import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import BusinessUnitSelector from 'components/common/BusinessUnitsSelector';
import { FollowUpConfig } from 'services/types/followUpConfig';

import AllCampaignsFollowUps from './components/AllCampaignsFollowUps';
import CreateFollowUpConfig from './components/CreateFollowUpConfig';
import HandleFile from './components/handleFile';
import SelectFollowUp from './components/selectFollowUp';

const Campaigns = () => {
  const [businessUnitId, setBusinessUnitId] = useState<number | null>(null);
  const [openCreateFollowUp, setOpenCreateFollowUp] = useState(false);
  const [openUploadBase, setOpenUploadBase] = useState(false);
  const [followUpSuccessAlert, setFollowUpSuccessAlert] = useState(false);
  const [clientUploadSuccessAlert, setClientUploadSuccessAlert] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState<FollowUpConfig | null>(null);
  const [openAllCampaignsFollowUps, setOpenAllCampaignsFollowUps] = useState(false);

  const resetForm = () => {
    setSelectedFollowUp(null);
    setOpenUploadBase(false);
    setOpenAllCampaignsFollowUps(false);
  };

  useEffect(() => {
    if (businessUnitId && businessUnitId !== -1) {
      resetForm();
    }
  }, [businessUnitId]);

  if (!businessUnitId || businessUnitId === -1)
    return (
      <Box pl={1} pt={1} display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h4" pr={1}>
          Selecciona un business unit para crear una campaña:
        </Typography>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={(value: number) => {
            setBusinessUnitId(value);
          }}
        />
      </Box>
    );

  return (
    <Box pl={2} py={2}>
      <AlertDialog
        openDialog={followUpSuccessAlert}
        setOpenDialog={setFollowUpSuccessAlert}
        header="Follow up creado!"
        msg="Follow up fue creado correctamente"
        cancelMsg="Cerrar"
      />
      <AlertDialog
        openDialog={clientUploadSuccessAlert}
        setOpenDialog={setClientUploadSuccessAlert}
        header="Clientes subidos!"
        msg="Clientes fueron subidos correctamente"
        cancelMsg="Cerrar"
      />
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center" pt={1}>
        <Typography variant="h3" pr={1}>
          Campañas:
        </Typography>
        <BusinessUnitSelector
          businessUnitId={businessUnitId}
          setBusinessUnitId={(value: number) => {
            setBusinessUnitId(value);
          }}
        />
        {!openCreateFollowUp && !openUploadBase && !openAllCampaignsFollowUps && (
          <>
            <PrimaryBtn onClick={() => setOpenCreateFollowUp(true)}>Crear follow up para campaña</PrimaryBtn>
            <PrimaryBtn onClick={() => setOpenUploadBase(true)}>Subir base para campaña</PrimaryBtn>
            <PrimaryBtn onClick={() => setOpenAllCampaignsFollowUps(true)}>Ver campañas</PrimaryBtn>
          </>
        )}
      </Box>

      {openCreateFollowUp && (
        <CreateFollowUpConfig
          businessUnitId={businessUnitId}
          onSuccess={() => {
            setFollowUpSuccessAlert(true);
            setOpenCreateFollowUp(false);
          }}
          setOpen={setOpenCreateFollowUp}
        />
      )}
      {openUploadBase && !selectedFollowUp && (
        <SelectFollowUp
          businessUnitId={businessUnitId}
          selectFollowUp={setSelectedFollowUp}
          setOpen={setOpenUploadBase}
        />
      )}
      {selectedFollowUp && (
        <HandleFile
          businessUnitId={businessUnitId}
          outboundCampaignId={selectedFollowUp.identifier}
          setOpen={() => {
            setOpenUploadBase(false);
            setSelectedFollowUp(null);
          }}
          setIsSuccess={() => {
            resetForm();
            setClientUploadSuccessAlert(true);
          }}
        />
      )}
      {openAllCampaignsFollowUps && (
        <AllCampaignsFollowUps businessUnitId={businessUnitId} setOpen={setOpenAllCampaignsFollowUps} />
      )}
    </Box>
  );
};

export default Campaigns;

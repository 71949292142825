import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { RollbarContext } from '@rollbar/react';
import useCurrentRoute from 'hooks/useCurrentRoute';

import AuthRoute from './authRoute';
import RoutesMapping from './routes';

const SITE_KEY = import.meta.env.VITE_RECAPTCHA_CLIENT_KEY || '';
const NODE_ENV = import.meta.env.NODE_ENV || 'development';

const AppRoutes = () => {
  const { title, path } = useCurrentRoute();
  useEffect(() => {
    document.title = title ? `Zeller - ${title}` : 'Zeller';
  }, [title]);

  const routesWithRecaptcha = ['/welcome', '/sign-in'];
  const loadScriptByURL = (id: string, url: string) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      script.src = url;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      script.id = id;
      document.body.appendChild(script);
    }
  };

  const removeScript = (id: string) => {
    const script = document.getElementById(id);
    if (script) {
      document.body.removeChild(script);
    }
  };

  const fullPath = window.location.pathname;

  useEffect(() => {
    if (NODE_ENV === 'test') {
      return;
    }
    if (
      fullPath.toLowerCase().includes('newchat') ||
      routesWithRecaptcha.includes(path) ||
      fullPath.toLowerCase().includes('realtimevoice')
    ) {
      loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    } else {
      removeScript('recaptcha-key');
      const recaptchaElements = document.getElementsByClassName('grecaptcha-badge');

      if (recaptchaElements.length) {
        recaptchaElements[0].remove();
      }
    }
  }, [path, fullPath]);
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Routes>
        {Object.values(RoutesMapping).map((route) => {
          return (
            <Route
              key={route.title}
              path={route.path}
              element={
                <RollbarContext context={route.path}>
                  <AuthRoute route={route} />
                </RollbarContext>
              }
            />
          );
        })}
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;

import React, { useEffect, useState, FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { useAdminExecuteQueryMutation, useAdminGenerateAIQueryMutation } from 'services/api/admin-db.api';
import type { DbOutput } from 'services/api/admin-db.api';
import {
  useGetFavoriteQueriesQuery,
  useCreateFavoriteQueryMutation,
  useDeleteFavoriteQueryMutation,
} from 'services/api/favoriteQueries.api';

const CellComponent: FC<{ value: unknown }> = (props: { value: unknown }) => {
  const { value } = props;
  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }
  if (typeof value === 'object') {
    return <div>{JSON.stringify(value)}</div>;
  }
  if (typeof value === 'boolean') {
    return <div>{value ? 'true' : 'false'}</div>;
  }
  return <div>{value}</div>;
};

const FavoriteQueryCard: FC<{
  title: string;
  query: string;
  id: number;
  onSelect: (query: string) => void;
  onDelete: (id: number) => void;
}> = ({ title, query, id, onSelect, onDelete }) => {
  return (
    <Box
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        p: 2,
        mb: 2,
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      <Box onClick={() => onSelect(query)}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {query}
        </Typography>
      </Box>
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8 }}
        onClick={(e) => {
          e.stopPropagation();
          onDelete(id);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const DbReader = () => {
  const [userQuery, setUserQuery] = useState<string>('');
  const [sqlQuery, setSQLQuery] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [favoriteTitle, setFavoriteTitle] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedQueryId, setSelectedQueryId] = useState<number | null>(null);

  const [generateAIQuery, { data: aiQueryData, isLoading }] = useAdminGenerateAIQueryMutation();
  const [executeQuery, { data: queryData, error, isLoading: isLoadingResult }] = useAdminExecuteQueryMutation();
  const { data: favoriteQueries, refetch: refetchFavoriteQueries } = useGetFavoriteQueriesQuery(null);
  const [createFavoriteQuery] = useCreateFavoriteQueryMutation();
  const [deleteFavoriteQuery] = useDeleteFavoriteQueryMutation();

  const handleGenerateAIQuery = () => {
    generateAIQuery({ userQuery }).catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  const handleExecuteQuery = () => {
    executeQuery({ userQuery: sqlQuery }).catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  const handleSelectFavoriteQuery = (query: string) => {
    setSQLQuery(query);
  };

  const handleSaveAsFavorite = async () => {
    await createFavoriteQuery({
      title: favoriteTitle,
      query: sqlQuery,
    });
    setIsModalOpen(false);
    setFavoriteTitle('');
    refetchFavoriteQueries().catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  const handleDeleteQuery = (id: number) => {
    setSelectedQueryId(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedQueryId) {
      await deleteFavoriteQuery(selectedQueryId);
      refetchFavoriteQueries().catch((err) => console.error(err)); // eslint-disable-line no-console
    }
    setDeleteDialogOpen(false);
    setSelectedQueryId(null);
  };

  useEffect(() => {
    if (aiQueryData) {
      setSQLQuery(aiQueryData.sqlQuery);
      setDescription(aiQueryData.description);
    }
  }, [aiQueryData]);

  useEffect(() => {
    console.log(queryData); // eslint-disable-line no-console
  }, [queryData]);

  useEffect(() => {
    if (error) {
      console.error(error); // eslint-disable-line no-console
      const alertMessage = `Error al ejecutar la consulta, revisa la consola para más información.
Revisa las comillas en los nombres de las tablas y columnas, y que las columnas existan en las tablas.`;
      alert(alertMessage); // eslint-disable-line no-alert
    }
  }, [error]);

  const downloadDataAsCSV = () => {
    if (queryData) {
      let fileData = `${Object.keys(queryData[0]).join(';')}\n`;
      fileData += queryData.map((row) => Object.values(row).join(';')).join('\n');
      const blob = new Blob([fileData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = 'db-data.csv';
      link.href = url;
      link.click();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleGenerateAIQuery();
    }
  };

  const rows =
    queryData?.map((data, id) => ({
      ...data,
      id: data.id && typeof data.id === 'number' ? `${id}: ${data.id}` : id,
    })) ?? [];
  const columns =
    queryData && queryData.length > 0
      ? Object.keys(queryData[0]).map(
          (key): GridColDef<DbOutput> => ({
            field: key,
            headerName: key,
            // Min width in characters: max(10, max length of the column). Max width: 80. Approx 10 px per character
            width:
              12 *
              Math.min(
                Math.max(
                  10,
                  ...queryData.map(
                    (row) =>
                      (typeof row[key] === 'object'
                        ? JSON.stringify(row[key])
                        : (row[key] as string | number)?.toString()
                      )?.length || 0
                  )
                ),
                80
              ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderCell: (params: GridRenderCellParams<any, string>) => <CellComponent value={params.value} />,
            type: 'string',
          })
        )
      : [];

  return (
    <Box m={2}>
      <Typography variant="h4">Analizador de Base de Datos</Typography>
      {/* Add padding to the container Box */}
      <TextField
        autoFocus
        fullWidth
        value={userQuery}
        onKeyPress={handleKeyPress}
        onChange={(e) => setUserQuery(e.target.value)}
        placeholder="Escribe lo que quieres preguntarle a la base de datos"
        label="Consulta en español"
        sx={{ marginBottom: '1rem' }}
      />
      {/* add spinner for loading query */}
      {isLoading && <Typography>Cargando...</Typography>}
      <Button onClick={handleGenerateAIQuery} sx={{ marginBottom: '1rem' }} variant="contained" disabled={isLoading}>
        Generar consulta
      </Button>
      <TextField
        fullWidth
        value={sqlQuery}
        rows={5}
        multiline
        onChange={(e) => setSQLQuery(e.target.value)}
        placeholder="Escribe la consulta que quieres ejecutar"
        label="Consulta SQL"
      />
      <Typography fontSize="13px" pb="10px" mt={0} pt={0}>
        {description}
      </Typography>
      {/* Add one button to execute the query */}
      {isLoadingResult && <Typography>Cargando...</Typography>}
      <Box display="flex" gap={2}>
        <Button onClick={handleExecuteQuery} disabled={!sqlQuery || isLoadingResult} variant="contained">
          Ejecutar consulta
        </Button>
        <Button onClick={() => setIsModalOpen(true)} disabled={!sqlQuery || isLoadingResult} variant="contained">
          Guardar como favorita
        </Button>
      </Box>
      {/* Show the results or favorite queries */}
      {queryData && queryData.length > 0 ? (
        <Box pt={2}>
          <Box display="flex" pb={2}>
            <Typography variant="h6">Resultados</Typography>
            <Button onClick={downloadDataAsCSV} sx={{ ml: 2 }} variant="contained">
              Descargar como CSV
            </Button>
          </Box>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: '14px',
              },
              color: 'black',
            }}
          />
        </Box>
      ) : (
        <Box pt={2}>
          <Typography variant="h6" gutterBottom>
            Consultas guardadas
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 2,
              width: '100%',
            }}
          >
            {favoriteQueries?.map((query) => (
              <FavoriteQueryCard
                key={query.id}
                id={query.id}
                title={query.title}
                query={query.query}
                onSelect={handleSelectFavoriteQuery}
                onDelete={handleDeleteQuery}
              />
            ))}
          </Box>
        </Box>
      )}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Guardar consulta como favorita</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Título de la consulta"
            fullWidth
            value={favoriteTitle}
            onChange={(e) => setFavoriteTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              // eslint-disable-next-line no-console
              handleSaveAsFavorite().catch((err) => console.error(err));
            }}
            disabled={!favoriteTitle}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>¿Estás seguro?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              confirmDelete().catch((err) => console.error(err)); // eslint-disable-line no-console
            }}
            color="error"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DbReader;

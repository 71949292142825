import React, { FC, SyntheticEvent } from 'react';

import { TextField, Checkbox, FormControl, Autocomplete } from '@mui/material';

import { useGetBusinessUnitSourcesQuery } from 'services/api/user.api';

interface SourceProps {
  sources: string[];
  setSources: (sources: string[]) => void;
  businessUnitId: number | null;
}

const SourcesSelector: FC<SourceProps> = ({ sources, setSources, businessUnitId }) => {
  const { data: sourcesData } = useGetBusinessUnitSourcesQuery({ businessUnitId });

  const definedSources = sourcesData?.filter((source) => !!source) || [];

  const handleSelectAll = () => {
    setSources(definedSources);
  };

  const handleSelectNone = () => {
    setSources([]);
  };

  const handleChange = (event: SyntheticEvent, value: string[]) => {
    if (value.includes('Seleccionar todos')) {
      handleSelectAll();
    } else if (value.includes('Seleccionar ninguno')) {
      handleSelectNone();
    } else {
      setSources(value);
    }
  };

  const optionsWithSelectAllNone = [
    sources.length === definedSources.length ? 'Seleccionar ninguno' : 'Seleccionar todos',
    ...definedSources,
  ];

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <Autocomplete
        multiple
        options={optionsWithSelectAllNone}
        value={sources}
        onChange={handleChange}
        disableCloseOnSelect
        renderInput={(params) => <TextField {...params} label="Origen" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        getOptionLabel={(option) => option || '--desconocido--'}
        isOptionEqualToValue={(option, value) => option === value}
      />
    </FormControl>
  );
};

export default SourcesSelector;

import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import ReplyIcon from '@mui/icons-material/Reply';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridFilterModel, GridSortModel, GridColDef } from '@mui/x-data-grid';

import { PrimaryBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import BusinessUnitTypeSelector from 'components/common/BusinessUnitTypeSelector';
import CustomDataGrid from 'components/common/CustomDataGrid';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'firebaseConfig';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import { useGetClientsFullListMutation, useGetClientsQuery, useGetClientsSourcesQuery } from 'services/api/clients.api';
import { Client } from 'services/types/client';

import AdvancedSearch from './AdvancedSearch';
import Alerts from './Alerts';

const last24hoursDate = new Date();
last24hoursDate.setDate(last24hoursDate.getDate() - 1);

const getBaseUserAttributes = (businessUnitType: string): GridColDef<Client>[] => {
  const baseColumns: GridColDef<Client>[] = [
    {
      field: 'businessUnit',
      headerName: 'Unidades de Negocio',
      width: 120,
      valueFormatter: (value: { name: string }) => value.name,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 250,
      renderCell(params) {
        return (
          <div style={{ color: params.row.isTestClient ? 'grey' : undefined, display: 'flex', alignItems: 'center' }}>
            {params.row.hasValidName && <CheckIcon color="success" sx={{ align: 'center' }} />}
            {params.row.disabledAutomaticResponses ? <SpeakerNotesOffIcon sx={{ mr: 1 }} /> : null}
            {params.row.firstName} {params.row.lastName}
            {params.row.clientMessagesCount > 0 ? (
              <ReplyIcon titleAccess="Cliente respondió" fontSize="small" sx={{ ml: 1 }} color="primary" />
            ) : null}
            {params.row.clientMessagesCount > 0 ? (
              <span style={{ color: 'grey', fontSize: '0.7em' }}>(x{params.row.clientMessagesCount})</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'source',
      headerName: 'Origen',
      width: 180,
    },
  ];
  if (businessUnitType === 'car_dealership') {
    baseColumns.push(
      {
        field: 'lastCRMUpdate',
        headerName: 'Subido CRM',
        width: 120,
        valueFormatter: (value: Date | null) => (value ? 'Si' : 'No'),
      },
      {
        field: 'visitDate',
        headerName: 'Agenda visita',
        width: 120,
        valueFormatter: (value: Date | null) => (value ? 'Si' : 'No'),
      },
      {
        field: 'brandOfInterest',
        headerName: 'Marca de interés',
        width: 150,
      },
      {
        field: 'modelsOfInterest',
        headerName: 'Modelo de interés',
        valueFormatter: (value: string[] | null) => (value ? value.at(-1) : ''),
        width: 150,
      },
      {
        field: 'outboundCampaignId',
        headerName: 'Campaña',
        width: 150,
      },
      {
        field: 'branchOffice',
        headerName: 'Sucursal',
        width: 300,
      },
      {
        field: 'requiresFinancing',
        headerName: 'Quiere Financiamiento',
        width: 150,
        valueFormatter: (value: boolean) => (value ? 'Si' : ''),
      }
    );
  }

  return [
    ...baseColumns,
    {
      field: 'lastMessageDate',
      headerName: 'Actualización',
      width: 140,
      valueFormatter: (value: Date) => {
        const dateValue = new Date(value);
        if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
        return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Creación',
      width: 140,
      valueFormatter: (value: Date) => {
        const dateValue = new Date(value);
        if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
        return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
          .getMinutes()
          .toString()
          .padStart(2, '0')}`;
      },
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 180,
    },
    {
      field: 'rut',
      headerName: 'RUT',
      width: 140,
    },
    {
      field: 'nationality',
      headerName: 'Nacionalidad',
      width: 110,
    },
    {
      field: 'dateOfBirth',
      headerName: 'Edad',
      width: 60,
      valueFormatter: (value: string | null) => {
        if (!value) return null;
        const birthday = new Date();
        birthday.setDate(Number(value.slice(0, 2)));
        birthday.setMonth(Number(value.slice(3, 5)));
        birthday.setFullYear(Number(value.slice(-4)));
        const ageDifMs = Date.now() - birthday.getTime();
        return Math.abs(new Date(ageDifMs).getUTCFullYear() - 1970);
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'messagesCount',
      headerName: 'Mensajes',
      width: 90,
    },
    {
      field: 'clientMessagesCount',
      headerName: 'Mensajes Cliente',
      width: 100,
    },
  ];
};

interface PaginationModel {
  page: number;
  pageSize: number;
}

interface ClientsQueryParams {
  paginationModel: PaginationModel;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  searchTerm?: string;
  businessUnitId?: number;
  businessUnitType?: 'car_dealership' | 'financial_advisor' | null;
}

interface Props {
  clients: Client[];
  filterModel: GridFilterModel;
  paginationModel: PaginationModel;
  sortModel: GridSortModel;
  setFilterModel: (model: GridFilterModel) => void;
  setPaginationModel: (model: PaginationModel) => void;
  setSortModel: (model: GridSortModel) => void;
  navigateToClientId: (id: number, newPage?: boolean) => void;
  total: number;
  highlightedClientId: string;
  businessUnitType: string;
  sources: string[];
}

const ClientsTable: FC<Props> = ({
  clients,
  filterModel,
  paginationModel,
  sortModel,
  setFilterModel,
  setPaginationModel,
  setSortModel,
  navigateToClientId,
  highlightedClientId,
  total,
  businessUnitType,
  sources,
}) => {
  const currentUser = useCurrentUser();

  const ADMIN_USER_ATTRIBUTES: GridColDef<Client>[] = [
    {
      field: 'AIRevision',
      headerName: 'Puntaje IA',
      width: 120,
      renderCell(params) {
        if (!params.row.AIRevision) return '-';
        const score = params.row.AIRevision.match(/Puntaje: (\d+)/);
        return (
          <Tooltip title={params.row.AIRevision}>
            <span>{score && score[1] ? score[1] : '*'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'imagesSent',
      headerName: 'Imágenes',
      width: 90,
    },
    {
      field: 'isTestClient',
      headerName: 'Test',
      width: 50,
      valueFormatter: (value: boolean) => (value ? 'Si' : ''),
    },
    {
      field: 'disabledAutomaticResponses',
      headerName: 'Sin IA',
      width: 60,
      valueFormatter: (value: boolean) => (value ? 'Si' : ''),
    },
    ...(businessUnitType === 'car_dealership'
      ? [
          {
            field: 'followUpsSent',
            headerName: 'Follow Ups',
            width: 100,
          },
          {
            field: 'failedMessagesCount',
            headerName: 'Mensajes fallidos',
            width: 100,
          },
          {
            field: 'hasValidatedAllData',
            headerName: 'Validó Datos',
            width: 90,
            valueFormatter: (value: boolean) => (value ? 'Si' : ''),
          },
          {
            field: 'floidRequestsCount',
            headerName: 'Floid',
            width: 60,
          },
          {
            field: 'floidRequestsDetails',
            headerName: 'Floid detalles',
            width: 250,
            valueFormatter: (value: string) =>
              value
                ?.replace(/REGISTRO_CIVIL/g, 'RegCivil')
                .replace(/CAMARA_COMERCIO/g, 'CCS')
                .replace(/200/g, 'OK')
                .replace(/400/g, 'ERROR'),
          },
        ]
      : []),
    {
      field: 'adminNotes',
      headerName: 'Notas Administrador',
      width: 400,
    },
  ];

  const scrollToHighlightedRow = () => {
    const rowIndex = clients.findIndex((client) => client.id.toString() === highlightedClientId);
    if (rowIndex < 5 || clients.length < 5) return;
    document.querySelector('.MuiDataGrid-virtualScroller')?.scrollTo({ top: 52 * (rowIndex - 3), behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToHighlightedRow();
    }, 400);
  }, []);

  useEffect(() => {
    scrollToHighlightedRow();
  }, [clients]);

  let columns = currentUser?.isAdmin
    ? getBaseUserAttributes(businessUnitType).concat(ADMIN_USER_ATTRIBUTES)
    : getBaseUserAttributes(businessUnitType);

  if (currentUser?.isAdmin) {
    // swap the businessUnit column to the first position
    const businessUnitColumn = columns.find((column) => column.field === 'businessUnit');
    if (businessUnitColumn) {
      columns = [businessUnitColumn, ...columns.filter((column) => column.field !== 'businessUnit')];
    }
    const AIRevisionColumn = columns.findIndex((column) => column.field === 'AIRevision');
    if (AIRevisionColumn > -1) {
      columns.splice(7, 0, columns[AIRevisionColumn]);
      columns.splice(AIRevisionColumn + 1, 1);
    }
  }

  return (
    <Box style={{ width: '100%' }} my={2}>
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: { xs: 0, sm: 2 },
        }}
      >
        <CustomDataGrid
          rows={clients}
          columns={columns}
          filterModel={filterModel}
          sortModel={sortModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={total}
          onRowClick={(params, event) => navigateToClientId(Number(params.id), event.ctrlKey || event.metaKey)}
          getRowClassName={(params) => (params.id.toString() === highlightedClientId ? 'Mui-selected' : '')}
          sources={sources}
        />
      </Box>
    </Box>
  );
};

const Clients: FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isMobile = useIsMobile();
  const queryParams = new URLSearchParams(window.location.search);
  const queryFilterModel = queryParams.get('filterModel');
  const queryPaginationModel = queryParams.get('paginationModel');
  const querySortModel = queryParams.get('sortModel');
  const querySearchTerm = queryParams.get('searchTerm');
  const queryBusinessUnitId = queryParams.get('businessUnitId');
  const queryBusinessUnitType = queryParams.get('businessUnitType') as 'car_dealership' | 'financial_advisor' | null;
  const highlightedClientId = queryParams.get('highlightedClientId') || '';

  const [searchTerm, setSearchTerm] = useState<string>(querySearchTerm || '');
  const parsedBusinessUnitId = parseInt(queryBusinessUnitId || '', 10);
  const [businessUnitId, setBusinessUnitId] = useState<number | null>(
    Number.isNaN(parsedBusinessUnitId) ? null : parsedBusinessUnitId
  );
  const [businessUnitTypeFilter, setBusinessUnitTypeFilter] = useState<'car_dealership' | 'financial_advisor' | null>(
    queryBusinessUnitType || null
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    queryFilterModel ? (JSON.parse(queryFilterModel) as GridFilterModel) : { items: [] }
  );
  const parsedQueryPaginationModel = queryPaginationModel
    ? (JSON.parse(queryPaginationModel) as PaginationModel)
    : null;
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(
    parsedQueryPaginationModel && parsedQueryPaginationModel.pageSize <= 100 && parsedQueryPaginationModel.page >= 0
      ? parsedQueryPaginationModel
      : {
          page: 0,
          pageSize: isMobile ? 50 : 100,
        }
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    querySortModel ? (JSON.parse(querySortModel) as GridSortModel) : []
  );
  const [allMessagesSearch, setAllMessagesSearch] = useState<string>('');
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState<boolean>(false);

  const { data, refetch } = useGetClientsQuery({
    paginationModel,
    filterModel,
    sortModel,
    searchTerm,
    businessUnitId: businessUnitId || undefined,
    businessUnitType: businessUnitTypeFilter,
  } as ClientsQueryParams);

  const { data: sources, refetch: refetchSources } = useGetClientsSourcesQuery({
    businessUnitId: businessUnitId || undefined,
  });

  const [getClientsFullList, { data: clientsFullList, isLoading: isLoadingClientsFullList }] =
    useGetClientsFullListMutation();

  const { clients, total } = data || { clients: [], total: 0 };

  const businessUnitType =
    new Set(clients.map((client) => client.businessUnit?.type)).size === 1 ? clients[0].businessUnit?.type : '';

  const downloadClientsFullList = (clientsList: Client[]) => {
    if (!clientsList || !Array.isArray(clientsList) || clientsList.length === 0) return;
    let csvContent =
      'Id;Nombre;Email;RUT;Teléfono;Marca;Sucursal;Subido a CRM;Agenda Visita;Puntaje;Ingresos;Ahorros/Pie;Nacionalidad;Estado Civil;Fecha Nacimiento;Actualización;Creación;Origen;Mensajes\n';
    clientsList.forEach((client) => {
      const rowContent = `${client.id};${client.firstName} ${client.lastName};${client.email};${client.rut};${
        client.phone
      };${client.brandOfInterest};${client.branchOffice};${client.lastCRMUpdate ? 'Si' : 'No'};${
        client.visitDate ? 'Si' : 'No'
      };${client.score};${client.totalSalary};${client.savingsAmount ?? '-'};${client.nationality ?? '-'};${
        client.maritalStatus ?? '-'
      };${!client.dateOfBirth ? '-' : new Date(client.dateOfBirth).toLocaleDateString('es-CL')};${new Date(
        client.lastMessageDate
      ).toLocaleDateString('es-CL')};${new Date(client.createdAt).toLocaleDateString('es-CL')};${client.source};${
        client.clientMessagesCount
      }`;
      csvContent += `${rowContent.replaceAll('\n', ' ')}\n`;
    });
    csvContent = csvContent.replaceAll('#', ''); // # are comments in csv

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);

    // const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'clientes.csv');
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error fetching clients');
    });
  }, [paginationModel, filterModel, sortModel, searchTerm, businessUnitId, businessUnitTypeFilter]);

  useEffect(() => {
    refetchSources().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error fetching sources');
    });
  }, [businessUnitId]);

  useEffect(() => {
    queryParams.delete('filterModel');
    // save filters in query params
    if (filterModel.items.length > 0) {
      queryParams.set('filterModel', JSON.stringify(filterModel));
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [filterModel]);

  useEffect(() => {
    queryParams.delete('paginationModel');
    // save pagination in query params
    queryParams.set('paginationModel', JSON.stringify(paginationModel));
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [paginationModel]);

  useEffect(() => {
    queryParams.delete('sortModel');
    // save sort in query params
    if (sortModel.length > 0) {
      queryParams.set('sortModel', JSON.stringify(sortModel));
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [sortModel]);

  useEffect(() => {
    queryParams.set('searchTerm', searchTerm);
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [searchTerm]);

  useEffect(() => {
    queryParams.set('businessUnitId', businessUnitId?.toString() || '');
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [businessUnitId]);

  useEffect(() => {
    queryParams.set('businessUnitType', businessUnitTypeFilter || '');
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [businessUnitTypeFilter]);

  useEffect(() => {
    if (clientsFullList) {
      downloadClientsFullList(clientsFullList);
    }
  }, [clientsFullList]);

  if (messaging) {
    onMessage(messaging, (payload) => {
      // eslint-disable-next-line no-console
      console.log('Message received from clients page:', payload);
      if (payload.data?.identifier === 'new-client-assigned' || payload.data?.identifier === 'new-client-message') {
        refetch().catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      }
    });
  }

  const navigateToClientId = (id: number, newPage = false) => {
    queryParams.set('highlightedClientId', id.toString());
    if (newPage) {
      window.open(`/clients/${id}`, '_blank');
      return;
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
    navigate(`/clients/${id}`);
  };

  return (
    <>
      {advancedSearchModalOpen && (
        <AdvancedSearch
          searchQuery={allMessagesSearch}
          open={advancedSearchModalOpen}
          setOpen={setAdvancedSearchModalOpen}
          selectClient={(client) => navigateToClientId(client.id, true)}
        />
      )}
      <Box alignItems="flex-start" mt={2} sx={{ ml: { xs: 6, sm: 10, md: 0 }, display: { xs: 'block', md: 'flex' } }}>
        <Typography variant="h5" textAlign="left">
          Lista de clientes
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <SearchBar
            searchTerm={searchTerm}
            onSearchTermChange={setSearchTerm}
            businessUnitId={businessUnitId}
            setBusinessUnitId={setBusinessUnitId}
          />
          <BusinessUnitTypeSelector
            businessUnitType={businessUnitTypeFilter}
            setBusinessUnitType={setBusinessUnitTypeFilter}
          />
        </Box>
        {currentUser?.isAdmin && (
          <Box display="flex" alignItems="center">
            <TextField
              label="Buscar en todos los mensajes"
              sx={{ width: '200px' }}
              variant="standard"
              onChange={(e) => setAllMessagesSearch(e.target.value)}
              value={allMessagesSearch}
            />
            <PrimaryBtn onClick={() => setAdvancedSearchModalOpen(true)}>Buscar</PrimaryBtn>
          </Box>
        )}
        <PrimaryBtn
          onClick={() => {
            getClientsFullList(null).catch(() => {
              // eslint-disable-next-line no-console
              console.error('Error fetching full client list');
            });
          }}
          disabled={isLoadingClientsFullList}
        >
          Descargar lista completa
        </PrimaryBtn>
      </Box>
      {/* todo: determine if alerts need to be filtered for some users */}
      <Alerts />
      <ClientsTable
        clients={clients}
        filterModel={filterModel}
        paginationModel={paginationModel}
        sortModel={sortModel}
        sources={sources || []}
        setFilterModel={setFilterModel}
        setPaginationModel={setPaginationModel}
        setSortModel={setSortModel}
        navigateToClientId={navigateToClientId}
        highlightedClientId={highlightedClientId}
        total={total}
        businessUnitType={businessUnitType || ''}
      />
    </>
  );
};

export default Clients;

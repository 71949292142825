import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Refresh } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Cancel from '@mui/icons-material/Cancel';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import Send from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Pusher from 'pusher-js';
import {
  useGetAnalysisQuery,
  useAddMessageToAnalysisMutation,
  useEditTitleToAnalysisMutation,
} from 'services/api/analyses.api';

import AskAssistantsToolComponent from './components/AskAssistantsToolComponent';
import CommonMessageComponent from './components/CommonMessageComponent';
import ExecuteSQLToolComponent from './components/ExecuteSQLToolComponent';
import GenerateChartToolComponent from './components/GenerateChartToolComponent';
import MessageWrapper from './components/MessageWrapper';
import SearchMessagesToolComponent from './components/SearchMessagesToolComponent';
import ToolResponseComponent from './components/ToolResponseComponent';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const Analyses: FC = () => {
  // get id from params
  const { analysisId } = useParams<{ analysisId: string }>();
  const { data: analysis, refetch } = useGetAnalysisQuery(Number(analysisId));

  const [messageToSend, setMessageToSend] = useState('');
  const [editTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [addMessageToAnalysis, { data }] = useAddMessageToAnalysisMutation();
  const [editTitleToAnalysis] = useEditTitleToAnalysisMutation();

  const refetchAnalysis = () => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching analysis');
    });
  };

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channelName = `analysis-${analysisId}`;
    const channel = pusher.subscribe(channelName);
    channel.bind('update', () => {
      refetchAnalysis();
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [analysisId]);

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching analysis');
    });
  }, [data]);

  useEffect(() => {
    const element = document.getElementById('text-input');
    if (element) element.scrollIntoView({ behavior: 'smooth' });
    setTitle(analysis?.query || '');
  }, [analysis]);

  if (!analysis) {
    return <div>Cargando...</div>;
  }

  const handleSendMessage = () => {
    // eslint-disable-next-line no-console
    console.log('Sending message', messageToSend);
    addMessageToAnalysis({ id: analysis.id, message: messageToSend }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error sending message', error);
    });
    setMessageToSend('');
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching analysis');
    });
  };

  const handleEditTitle = () => {
    // eslint-disable-next-line no-console
    console.log('Editing title', title);
    editTitleToAnalysis({ id: analysis.id, query: title }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error editing title', error);
    });
    setEditTitle(false);
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching analysis');
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      handleSendMessage();
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <IconButton onClick={() => window.history.back()}>
          <ArrowBack />
        </IconButton>
        {!editTitle && (
          <Typography variant="h4">
            {title}
            {/* // Add an edit button */}
            <IconButton onClick={() => setEditTitle(true)} sx={{ ml: 2 }}>
              <Edit />
            </IconButton>
          </Typography>
        )}
        {editTitle && (
          <Box width="50%" flexDirection="column">
            <TextField value={title} fullWidth onChange={(e) => setTitle(e.target.value)} autoFocus />
            <IconButton onClick={() => handleEditTitle()} sx={{ ml: 2 }}>
              <Save />
            </IconButton>
            <IconButton onClick={() => setEditTitle(false)}>
              <Cancel />
            </IconButton>
          </Box>
        )}
        <IconButton onClick={refetchAnalysis}>
          <Refresh />
        </IconButton>
      </Box>
      <Box mr={8} ml={8}>
        {analysis.history.map((message, index) => (
          <Box key={`${index + 1}`} display="flex" justifyContent="flex-start" m={2} mt={1} flexDirection="column">
            {message.role === 'user' && <CommonMessageComponent message={message} />}
            {message.role === 'assistant' && message.content && <CommonMessageComponent message={message} />}
            {message.toolCalls?.map((toolCall) => (
              <Box key={toolCall.id} display="flex" justifyContent="flex-start" mt={1} flexDirection="column">
                {toolCall.function.name === 'askAssistantsToReviewConversations' && (
                  <AskAssistantsToolComponent args={toolCall.function.arguments} />
                )}
                {toolCall.function.name === 'generateChart' && (
                  <GenerateChartToolComponent args={toolCall.function.arguments} />
                )}
                {toolCall.function.name === 'executeSQL' && (
                  <ExecuteSQLToolComponent args={toolCall.function.arguments} />
                )}
                {toolCall.function.name === 'searchMessages' && (
                  <SearchMessagesToolComponent args={toolCall.function.arguments} />
                )}
              </Box>
            ))}
            {message.role === 'tool' && <ToolResponseComponent message={message} history={analysis.history} />}
          </Box>
        ))}
        {analysis.status === 'pending' && (
          <Box m={2}>
            <CommonMessageComponent message={{ role: 'assistant', content: 'Pensando...' }} />
          </Box>
        )}
      </Box>
      {/* Add an input text using the message MessageWrapper */}
      <Box mr={8} mb={2} id="text-input">
        <MessageWrapper caption="Usuario" align="right">
          <Box display="flex" width="500px" m={2} flexDirection="row">
            <Grid container justifyContent="space-between">
              <TextField
                id="outlined-basic-email"
                placeholder="Escribe un mensaje"
                multiline
                sx={{
                  width: '80%',
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  opacity: 1,
                  mr: 1,
                  '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                }}
                onKeyPress={handleKeyPress}
                value={messageToSend}
                onChange={(e) => setMessageToSend(e.target.value)}
                disabled={analysis.status === 'pending'}
              />
              <IconButton
                sx={{
                  height: '3.5rem',
                }}
                onClick={handleSendMessage}
              >
                <Send
                  sx={{
                    fontSize: '2.5rem',
                  }}
                />
              </IconButton>
            </Grid>
          </Box>
        </MessageWrapper>
      </Box>
      {analysis.cost && analysis.cost > 0 ? (
        <span style={{ color: 'white' }}>Costo: ${Math.round(analysis.cost)}</span>
      ) : null}
    </Box>
  );
};

export default Analyses;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import useCurrentUser from 'hooks/useCurrentUser';
import { setUserName } from 'redux/slices/user.slice';
import { useUpdateNameMutation } from 'services/api/user.api';

import ChangePassword from './ChangePassword';
import NotificationsPermissions from './NotificationsPermissions';

const Profile = () => {
  const currentUser = useCurrentUser();
  const [name, setName] = useState(currentUser?.name || '');
  const dispatch = useDispatch();

  const [updateName, { isLoading }] = useUpdateNameMutation();

  const handleNameSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    updateName({ name }).catch((error) => console.error(error));
    dispatch(setUserName(name));
  };

  return (
    <Box sx={{ p: { xs: 2 } }}>
      <Typography variant="h4" mt={2} sx={{ ml: { xs: 6, sm: 10, md: 0 } }}>
        Mi Perfil:
      </Typography>
      <Box component="form" onSubmit={handleNameSubmit}>
        <TextField
          fullWidth
          sx={{ my: 1 }}
          label="Nombre"
          value={name}
          onChange={(event) => setName(event.target.value)}
          variant="outlined"
        />
        <PrimaryBtn
          type="submit"
          fullWidth
          variant="contained"
          disabled={!name || isLoading}
          sx={{ maxWidth: '200px', margin: 'auto' }}
        >
          {isLoading ? 'Actualizando...' : 'Cambiar nombre'}
        </PrimaryBtn>
      </Box>
      <ChangePassword />
      <NotificationsPermissions />
    </Box>
  );
};

export default Profile;

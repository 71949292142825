import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { DeleteIcon } from 'assets/icons';
import { PrimaryBtn } from 'components/UI/Buttons';
import useIsAdmin from 'hooks/useIsAdmin';
import NewPromptSectionButton from 'pages/PromptSections/components/NewPromptSectionButton';
import PromptSectionsForBusinessUnit from 'pages/PromptSections/components/PromptSectionsForBusinessUnit';
import {
  useDeleteBusinessUnitImageMutation,
  useEditBusinessUnitMutation,
  useGetBusinessUnitQuery,
  useUploadBusinessUnitImagesMutation,
} from 'services/api/businessUnits.api';
import { useGetPromptSectionsQuery } from 'services/api/promptSections.api';
import { PromptSection } from 'services/types/promptSection';

import styles from './BusinessUnitConfig.module.scss';
import BusinessUnitAlerts from './components/businessUnitAlerts';

const PROMPT_HELP_TEXT = (
  <Box mt={2}>
    <Typography variant="h4">Instrucciones para el prompt</Typography>
    Dentro del prompt se pueden usar las siguientes variables:
    <br />
    <b>{'<CLIENT:attribute>'}</b>: Incluir un atributo del cliente. (Ej: {'<CLIENT:Rut>'})
    <br />
    <b>{'<CURRENT_DATE>'}</b>: Fecha actual
    <br />
    <b>{'<UF_PRICE>'}</b>: Valor actual de la UF
    <br />
    <b>{'<IF:attr>Texto</IF:attr>'}</b>: Incluye el &quot;Texto&quot; solo si existe el atributo
    <br />
    <b>{'<IF:!attr>Texto</IF:!attr>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo no es válido, es falso o es
    cero.
    <br />
    <b>{'<IF:attr_operador_valor>Texto</IF:attr_operador_valor>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo
    cumple la comparación.
    <br />
    <b>{'<SECTION:identifier>'}</b>: Incluye una sección de prompt. Puedes ver las disponibles en &quot;Ver secciones de
    prompt&quot;
    <br />
    <b>{'<WORKING_HOURS>Texto</WORKING_HOURS>'}</b>: Incluye el &quot;Texto&quot; solo si estamos en horario laboral
    <br />
    <b>{'<OUT_OF_WORKING_HOURS>Texto</OUT_OF_WORKING_HOURS>'}</b>: Incluye el &quot;Texto&quot; solo si estamos fuera de
    horario laboral
    <br />
    <Box padding="20px">
      En <b>attr</b> puede ir <i>rut</i>, <i>email</i>, <i>salary</i>, <i>savingsAmount</i>, etc
      <br />
      En <b>operador</b> puede ir <i>=</i> (igual), <i>!=</i> (distinto), <i>&gt;</i> (mayor), <i>&lt;</i>, <i>&gt;=</i>
      , <i>&lt;=</i>, <i>startsWith</i>, <i>endsWith</i>, <i>contains</i>
      <br />
      En <b>valor</b> puede ir un número, un string, un true (Si) o un false (No)
      <br />
      Ejemplos:
      <ul>
        <li>{'<IF:email_contains_@salfa>Este es un empleado de Salfa, trátalo bien</IF:email_contains_@salfa>'}</li>
        <li>{'<IF:messagesCount_>_4><IF:!rut>Debes pedir el rut</IF:!rut></IF:messagesCount_>_4>'}</li>
        <li>
          {
            '<IF:nationality><IF:nationality_!=_CHILENA>Pregunta si tiene residencia definitiva</IF:nationality_!=_CHILENA></IF:nationality>'
          }
        </li>
        <li>
          {
            '<IF:clientMessagesCount_>_5>Es un cliente interesado, guíalo a visitar la sucursal</IF:clientMessagesCount_>_5>'
          }
        </li>
        <li>{'<IF:imagesSent_=_0>Pregúntale si quiere fotos</IF:imagesSent_=_0>'}</li>
        <li>{'<IF:imagesSent_>_0>Pregúntale si quiere más fotos</IF:imagesSent_>_0>'}</li>
        <li>{'<IF:floidRequestsCount_>_0>Tiene 1+ floid requests</IF:floidRequestsCount_>_0>'}</li>
      </ul>
      En attr se puede poner uno de los siguientes atributos (debe ser exacto, considerando las mayúsculas y minúsculas,
      no lo ocupes si no sabes qué significa):
      <ul>
        <li>email</li>
        <li>rut</li>
        <li>phone</li>
        <li>firstName</li>
        <li>lastName</li>
        <li>motherLastName</li>
        <li>savingsAmount</li>
        <li>notes</li>
        <li>adminNotes</li>
        <li>dateOfBirth</li>
        <li>gender</li>
        <li>nationality</li>
        <li>maritalStatus</li>
        <li>salary</li>
        <li>salarySupplement</li>
        <li>score</li>
        <li>lastMessageDate</li>
        <li>quote</li>
        <li>messagesCount</li>
        <li>imagesSent</li>
        <li>followUpsSent</li>
        <li>floidRequestsCount</li>
        <li>floidRequestsDetails</li>
        <li>isTestClient</li>
        <li>disabledAutomaticResponses</li>
        <li>source</li>
        <li>status</li>
        <li>userScore</li>
        <li>financialScore</li>
        <li>interestScore</li>
        <li>manualScore</li>
        <li>prospectMessage</li>
        <li>aiSafetyStatus</li>
      </ul>
      <a
        href="https://www.loom.com/share/c9e2f8008df44b82bf04eedf9ff95bd8?sid=f4404379-26c6-4421-8693-68f75a62b08e"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 1
      </a>
      <br />
      <a
        href="https://www.loom.com/share/8ca6921e97c74631bcee788ad8d1ca6c?sid=d04e35a1-3c09-45c1-a482-6fde8cda3397"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 2
      </a>
      <br />
      <a
        href="https://www.loom.com/share/8ff296ef858141d08c00654b635106f7?sid=ace50e83-e46e-407d-9a2b-335abd079198"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 3
      </a>
    </Box>
  </Box>
);

const BusinessUnits = () => {
  const { businessUnitId } = useParams();
  const { data: businessUnit, refetch } = useGetBusinessUnitQuery(Number(businessUnitId));
  const { data: promptSections } = useGetPromptSectionsQuery({ businessUnitId: Number(businessUnitId) });
  const [prompt, setPrompt] = useState<string>('');
  const [chatRevisionPrompt, setChatRevisionPrompt] = useState<string | null>(null);
  const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
  const [refetchPromptSectionsFlag, setRefetchPromptSectionsFlag] = useState<number>(0);
  const [showInstructions, setShowInstructions] = useState(false);
  const [updateBusinessUnit] = useEditBusinessUnitMutation();
  const [deleteBusinessUnitImage] = useDeleteBusinessUnitImageMutation();
  const [showPrettyPrompt, setShowPrettyPrompt] = useState(false);
  const [showPromptSections, setShowPromptSections] = useState(false);
  const [showStructuredPrompt, setShowStructuredPrompt] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<File[] | null>(null);
  const [uploadBusinessUnitImages] = useUploadBusinessUnitImagesMutation();
  const [functions, setFunctions] = React.useState<string[]>([]);
  const availableFunctions = [
    'sendCarImage',
    'sendImages',
    'sendVideo',
    'saveAndValidateEmail',
    'saveAndValidateRut',
    'calculateCreditForAmount',
    'calculateAutoCreditForAmount',
    'findNewCarsForClient',
    'findUsedCarsForClient',
    'sendQuote',
    'getPompeyoClient',
    'savePompeyoClient',
    'getPompeyoClientCars',
    'savePompeyoClientCar',
    'getAvailability',
    'getAvailableHours',
    'scheduleHour',
    'getCarServices',
    'getServicesCosts',
    'saveClientName',
    'getBranchOffices',
    'scheduleVisit',
    'scheduleBusinessUnitVisit',
    'calculateForumCreditForAmount',
    'registerQuestionWithoutKnownResponse',
    'saveFinancingStatus',
    'saveWantsToBeContacted',
    'getCarMonthlyFee',
    'getForumCreditStatus',
    'registerInformation',
    'getBankOffers',
    'createDebt',
    'saveWantsToBeContactedKaufmann',
    'saveSurveyResults',
    'getAstaraBranchOfficesByCommune',
    'saveCarToSell',
    'saveAstaraBranchOfficeInfoForClient',
    'setInterestInBankOffer',
    'saveClientPrivacyPolicyAccepted',
    'saveAndValidatePeruDNI',
    'getMortgageLoanSimulation',
    'getAndSaveBankOffers',
    'getPensionSimulator',
    'assignClientToUser',
    'getBrunoFritschAvailability',
    'scheduleBrunoFritschAppointment',
    'saveTransactions',
    'deleteBrunoFritschAppointment',
  ].sort();

  const handleUpdate = () => {
    if (!businessUnit) return;
    updateBusinessUnit({
      id: businessUnit.id,
      businessUnit: {
        prompt,
        availableFunctions: functions,
        chatRevisionPrompt,
      },
    }).catch((err) => console.log(err)); // eslint-disable-line
  };

  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const handleDeleteImage = (fileName: string) => {
    if (businessUnit) {
      deleteBusinessUnitImage({ businessUnitId: businessUnit.id, fileName })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const handleUploadImages = () => {
    if (imagesToUpload && businessUnit) {
      uploadBusinessUnitImages({ businessUnitId: businessUnit.id, files: imagesToUpload })
        .then(() => {
          setRefetchFlag(!refetchFlag);
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const getImagesError = () => {
    if (!imagesToUpload || !imagesToUpload.length) {
      return 'No hay imágenes seleccionadas';
    }
    if (imagesToUpload.some((file) => file.size > 300_000)) {
      return 'Hay imágenes que pesan más de 300kb';
    }
    return null;
  };

  const handleChange = (event: SelectChangeEvent<typeof functions>) => {
    const {
      target: { value },
    } = event;
    setFunctions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const tagStack: { id: string; type: string }[] = [];
  let tagCounter = 0;

  const htmlPrompt = prompt
    .replaceAll('>', '&gt;')
    .replaceAll('<', '&lt;')
    .replaceAll('\n', '<br />')
    // Add special style to if tags
    .replaceAll(/&lt;IF:([^&]+)&gt;/g, (str, type: string) => {
      const id = `tag-${tagCounter}`;
      tagStack.push({ id, type });
      tagCounter += 1;
      return `<span id="${id}" class="if-tag" data-related="${id}-close" style="color: blue;">${str}</span><div id="${id}-content" style="display: block;">`;
    })
    // Add special style to closing if tags
    .replaceAll(/&lt;\/IF:([^&]+)&gt;/g, (str, type) => {
      // Finds the first opening if tag with the same type
      const index = tagStack.findIndex((tag) => tag.type === type);
      if (index !== -1) {
        // Removes the opening if tag from the stack
        const { id } = tagStack.splice(index, 1)[0];
        return `</div><span id="${id}-close" class="if-tag" data-related="${id}" style="color: blue;">${str}</span>`;
      }
      return str; // Return the original string if no matching opening tag is found
    })
    // Add special style to other tags
    .replaceAll(/&lt;(?!\/?IF:)\S*?[^_]&gt;/g, (str) => `<span style="color: red;">${str}</span>`)
    // Add special style to comments
    .replaceAll(/&lt;!--[\S\s]*?--&gt;/g, (str) => `<span style="color: grey;">${str}</span>`)
    // Add special style to links
    .replaceAll(/https[^\n\r<]+/g, (str) => `<span style="color: blue; text-decoration: underline">${str}</span>`)
    // Add special style to hashtags
    .replaceAll(/#+\s[^\n\r<]+/g, (str) => `<span style="font-weight: bold;">${str}</span>`);

  // eslint-disable-next-line react/no-danger
  const prettyPrompt = <div id="dummy" className="original" dangerouslySetInnerHTML={{ __html: htmlPrompt }} />;

  useEffect(() => {
    const tags = document.querySelectorAll('.if-tag');
    tags.forEach((tag) => {
      // Highlight tag (and its corresponding closing or opening tag) when mouse is over it
      tag.addEventListener('mouseover', () => {
        const relatedId = tag.getAttribute('data-related');
        if (!relatedId) return;
        const relatedTag = document.getElementById(relatedId);
        if (relatedTag) {
          tag.classList.add(styles.highlight);
          relatedTag.classList.add(styles.highlight);
        }
      });
      // Remove highlight when mouse leaves tag
      tag.addEventListener('mouseout', () => {
        const relatedId = tag.getAttribute('data-related');
        if (!relatedId) return;
        const relatedTag = document.getElementById(relatedId);
        if (relatedTag) {
          tag.classList.remove(styles.highlight);
          relatedTag.classList.remove(styles.highlight);
        }
      });
      // Toggle content visibility when clicking on tag
      tag.addEventListener('click', () => {
        const contentId = `${tag.id.replace('-close', '')}-content`;
        const content = document.getElementById(contentId);
        if (content) {
          content.style.display = content.style.display === 'block' ? 'none' : 'block';
        }
      });
    });
  }, [prettyPrompt]);

  useEffect(() => {
    if (businessUnit) {
      setPrompt(businessUnit.prompt);
      setFunctions(businessUnit.availableFunctions || []);
      setChatRevisionPrompt(businessUnit.chatRevisionPrompt || null);
    }
  }, [businessUnit]);

  useEffect(() => {
    refetch().catch((err) => console.log(err)); // eslint-disable-line
  }, [refetchFlag]);

  const getStructuredPrompt = () => {
    if (!promptSections) return prompt;

    return prompt
      .split('\n')
      .map((line) => {
        const sectionMatch = line.match(/<SECTION:([^>]+)>/);
        if (sectionMatch) {
          const sectionName = sectionMatch[1];
          const section = promptSections.find(
            (s: PromptSection) => s.name === sectionName && s.isLastVersion && !s.isSuggestion
          );
          if (section) {
            return section.value;
          }
        }
        return line;
      })
      .join('\n');
  };

  const getStructuredPromptWithUI = () => {
    if (!promptSections) return prompt;

    return prompt
      .split('\n')
      .map((line) => {
        const sectionMatch = line.match(/<SECTION:([^>]+)>/);
        if (sectionMatch) {
          const sectionName = sectionMatch[1];
          const section = promptSections.find(
            (s: PromptSection) => s.name === sectionName && s.isLastVersion && !s.isSuggestion
          );
          if (section) {
            return `<div class="${styles.promptSectionContainer}">
              <div class="${styles.promptSectionHeader}">
                <span>${section.name}: ${section.displayName}</span>
                <button class="${styles.copySectionButton}" data-section-value="${section.value.replace(
                  /"/g,
                  '&quot;'
                )}">
                  <svg style="width: 14px; height: 14px;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                  </svg>
                  <span class="${styles.copySectionText}">Copiar Prompt Section</span>
                </button>
              </div>
              <div class="${styles.promptSection}">${section.value}</div>
            </div>`;
          }
        }
        return line;
      })
      .join('\n');
  };

  useEffect(() => {
    const copyButtons = document.querySelectorAll(`.${styles.copySectionButton}`);
    copyButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.stopPropagation();
        const value = button.getAttribute('data-section-value');
        if (value) {
          navigator.clipboard.writeText(value).catch(console.error); // eslint-disable-line
        }
      });
    });
  }, [showStructuredPrompt]);

  if (!businessUnit || !isAdmin) {
    return <Typography variant="h3">No se encontró la empresa</Typography>;
  }

  return (
    <Box pl={1}>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Empresa</Typography>
        <PrimaryBtn sx={{ ml: 2 }} onClick={() => navigate(-1)} variant="contained">
          Volver
        </PrimaryBtn>
      </Box>
      <Typography variant="body1">
        <b>Nombre:</b> {businessUnit?.name}
      </Typography>
      <Typography variant="body1">
        <b>Teléfono:</b> {businessUnit?.phoneNumber}
      </Typography>
      <Typography variant="body1">
        <b>WhatsApp Tiers:</b>{' '}
        {businessUnit?.phoneNumberTier?.map((tier) => `${tier.split('T')[0]} ${tier.split(':').at(-1)}`).join(', ')}
      </Typography>
      <Typography variant="body1">
        <b>Prompt</b>
      </Typography>
      <Box px={1}>
        <textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          style={{ width: '100%', height: '400px' }}
        />
      </Box>
      <Box py={2}>
        <FormControl sx={{ minWidth: 250, maxWidth: '100%' }}>
          <InputLabel>Funciones disponibles</InputLabel>
          <Select
            label="Funciones disponibles"
            multiple
            value={functions}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            autoWidth
          >
            {availableFunctions.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={functions.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="body1">
        <b>Prompt para revision de chat</b>
      </Typography>
      <Box px={1}>
        <textarea
          value={chatRevisionPrompt || ''}
          onChange={(e) => setChatRevisionPrompt(e.target.value === '' ? null : e.target.value)}
          style={{ width: '100%', height: '200px' }}
        />
      </Box>
      <PrimaryBtn sx={{ mt: 2 }} onClick={handleUpdate} variant="contained">
        Actualizar
      </PrimaryBtn>
      <Typography variant="h6" pt="25px">
        Videos:
      </Typography>
      {businessUnit?.videos && (
        <>
          {Object.keys(businessUnit.videos).map((key) => (
            <div key={key}>
              <a
                href={businessUnit.videos?.[key]?.publicFileURL}
                target="_blank"
                rel="noreferrer"
                key={key}
                style={{ textDecoration: 'underline' }}
              >
                {key}
              </a>
            </div>
          ))}
        </>
      )}
      <Typography variant="h6" pt="25px">
        Imágenes:
      </Typography>
      {businessUnit?.images && (
        <>
          {Object.keys(businessUnit.images).map((key) => (
            <div key={key}>
              <a
                href={businessUnit.images?.[key]?.publicFileURL}
                target="_blank"
                rel="noreferrer"
                key={key}
                style={{ textDecoration: 'underline' }}
              >
                {key}
              </a>
              <IconButton onClick={() => handleDeleteImage(key)} color="error">
                <DeleteIcon />
              </IconButton>
              <br />
            </div>
          ))}
        </>
      )}
      <label htmlFor="file">
        <input
          id="file"
          type="file"
          accept=".jpg,.jpeg,.png"
          multiple
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              setImagesToUpload(Array.from(e.target.files));
            } else if (e.target.files && !e.target.files.length) {
              setImagesToUpload(null);
            }
          }}
          placeholder="Seleccionar imágenes"
        />
      </label>
      <br />
      <PrimaryBtn onClick={handleUploadImages} disabled={!!getImagesError()} sx={{ my: 2 }} variant="contained">
        Subir imágenes
      </PrimaryBtn>
      {getImagesError() && <Typography variant="body1">{getImagesError()}</Typography>}
      <BusinessUnitAlerts businessUnit={businessUnit} />
      <Box>
        <Box display="flex">
          <Switch checked={showPrettyPrompt} onChange={() => setShowPrettyPrompt(!showPrettyPrompt)} />
          <Typography variant="h6">Ver Prompt Más bonito</Typography>
        </Box>
        <Box px={4}>{showPrettyPrompt && prettyPrompt}</Box>
      </Box>
      <hr />
      <Box>
        <Box display="flex">
          <Switch checked={showInstructions} onChange={() => setShowInstructions(!showInstructions)} />
          <Typography variant="h6">Ver instrucciones</Typography>
        </Box>
        <Box>{showInstructions && PROMPT_HELP_TEXT}</Box>
      </Box>
      <hr />
      <Box>
        <Box display="flex">
          <Switch checked={showPromptSections} onChange={() => setShowPromptSections(!showPromptSections)} />
          <Typography variant="h6">Ver secciones del prompt</Typography>
        </Box>
        {showPromptSections && businessUnitId && (
          <Box m={2}>
            <Box mb={1}>
              <NewPromptSectionButton
                businessUnitId={Number(businessUnitId)}
                refetch={() => setRefetchPromptSectionsFlag(refetchPromptSectionsFlag + 1)}
              />
            </Box>
            <PromptSectionsForBusinessUnit
              businessUnitId={Number(businessUnitId)}
              refetchFlag={refetchPromptSectionsFlag}
            />
          </Box>
        )}
      </Box>
      <hr />
      <Box>
        <Box display="flex">
          <Switch checked={showStructuredPrompt} onChange={() => setShowStructuredPrompt(!showStructuredPrompt)} />
          <Typography variant="h6">Ver Prompt Estructurado</Typography>
        </Box>
        {showStructuredPrompt && promptSections && prompt && (
          <Box px={4}>
            <Box
              sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: '4px 4px 0 0',
                padding: 1,
                borderBottom: '1px solid #e0e0e0',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(getStructuredPrompt()).catch(console.error); // eslint-disable-line
                }}
                title="Copiar prompt completo"
                size="small"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
              >
                <ContentCopyIcon fontSize="small" />
                <Typography variant="body2">Copiar Prompt</Typography>
              </IconButton>
            </Box>
            <Box
              className={styles.structuredPromptContainer}
              dangerouslySetInnerHTML={{ __html: getStructuredPromptWithUI() }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BusinessUnits;

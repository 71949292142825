import React, { FC, useState, useEffect } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import UpLeftArrow from '@mui/icons-material/NorthWest';
import Send from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { usePostFileMessageMutation, usePostMessageMutation } from 'services/api/messages';
import { Client } from 'services/types/client';
import { Message, MessageStatus } from 'services/types/message';

interface Props {
  client: Client;
  conversation: Message[] | undefined;
  setConversation: (messages: Message[]) => void;
}

const ChatFooter: FC<Props> = ({ client, conversation, setConversation }) => {
  const [active, setActive] = useState(false);
  const [messageToSend, setMessage] = useState('');
  const [fileToSend, setFileToSend] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const [postMessageMutation, { data: newMessage }] = usePostMessageMutation();
  const [postFileMutation, { data: newFileMessage }] = usePostFileMessageMutation();

  const handleSendMessage = () => {
    if (messageToSend.length === 0) {
      return;
    }
    // eslint-disable-next-line no-console
    postMessageMutation({ clientId: client.id, text: messageToSend }).catch((err) => console.error(err));
    setMessage('');
  };

  useEffect(() => {
    if (conversation) {
      const yesterday = new Date();
      yesterday.setHours(new Date().getHours() - 24);
      if (conversation.find((message) => message.role === 'user' && new Date(message.createdAt) > yesterday)) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (newMessage && conversation) {
      const newMessageWithSentStatus = { ...newMessage, status: 'sent' as MessageStatus };
      setConversation([...conversation, newMessageWithSentStatus]);
    }
  }, [newMessage]);

  useEffect(() => {
    if (newFileMessage && conversation) {
      setConversation([...conversation, newFileMessage]);
    }
  }, [newFileMessage]);

  useEffect(() => {
    if (fileToSend) {
      setOpenModal(true);
      if (fileToSend.size > 500000) {
        setError('El archivo no puede pesar más de 500kb');
        return () => {};
      }
      const objectUrl = URL.createObjectURL(fileToSend);
      setFilePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
    return () => {};
  }, [fileToSend]);

  if (!active) {
    return (
      <Grid container style={{ padding: '20px' }} alignItems="center" flexDirection="column">
        {conversation?.length === 0 ? (
          <Typography>No se ha hablado con este usuario, envíe una plantilla para comenzar una conversación</Typography>
        ) : (
          <Typography>Chat no está activo, pasaron más de 24 horas desde el último contacto del usuario</Typography>
        )}
        <Typography>Puedes enviar un template usando las opciones del chat</Typography>
        <UpLeftArrow fontSize="small" sx={{ ml: 1 }} />
      </Grid>
    );
  }

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {error && (
            <>
              <Typography variant="h5" color="error">
                {error}
              </Typography>
              <PrimaryBtn
                variant="contained"
                color="error"
                onClick={() => {
                  setFileToSend(null);
                  setOpenModal(false);
                  setError(null);
                }}
                sx={{ mr: 2 }}
              >
                Cerrar
              </PrimaryBtn>
            </>
          )}
          {!error && (
            <>
              <Typography variant="h5">
                <b>{fileToSend?.name.includes('.pdf') ? 'Archivo' : 'Imagen'} a enviar:</b>
                {fileToSend?.name}
              </Typography>
              {filePreview &&
                (fileToSend?.name.includes('.pdf') ? (
                  <embed src={filePreview} width="100%" height="700px" />
                ) : (
                  <img
                    src={filePreview}
                    alt={fileToSend?.name ?? 'file to send'}
                    width="100%"
                    height="auto"
                    style={{ maxHeight: '700px' }}
                  />
                ))}
              <PrimaryBtn
                variant="contained"
                color="error"
                onClick={() => {
                  setFileToSend(null);
                  setOpenModal(false);
                }}
                sx={{ mr: 2 }}
              >
                Cancelar
              </PrimaryBtn>
              <PrimaryBtn
                variant="contained"
                onClick={() => {
                  if (fileToSend) {
                    // eslint-disable-next-line no-console
                    postFileMutation({ clientId: client.id, file: fileToSend }).catch((err) => console.error(err));
                    setFileToSend(null);
                    setOpenModal(false);
                  }
                }}
              >
                Enviar
                <Send fontSize="large" />
              </PrimaryBtn>
            </>
          )}
        </Box>
      </Modal>
      <Box
        display="flex"
        width="100%"
        py={2}
        justifyContent="space-between"
        sx={{
          backgroundColor: '#fff',
        }}
      >
        <label htmlFor="file-input">
          <input
            id="file-input"
            name="file-input"
            type="file"
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png,.pdf"
            onChange={(e) => {
              if (e.target.files) {
                setFileToSend(e.target.files[0]);
              }
            }}
          />
          <Button variant="text" component="span">
            <AttachFileIcon fontSize="large" />
          </Button>
        </label>
        <TextField
          id="outlined-basic-email"
          label="Mensaje"
          fullWidth
          multiline
          sx={{
            backgroundColor: '#fff',
            borderRadius: '40px',
            '& .MuiOutlinedInput-root': { borderRadius: '40px' },
          }}
          value={messageToSend}
          onChange={(e) => setMessage(e.target.value)}
        />
        <IconButton size="large" onClick={handleSendMessage} disabled={messageToSend.length === 0}>
          <Send fontSize="large" />
        </IconButton>
      </Box>
    </>
  );
};

export default ChatFooter;

import { BusinessUnit } from 'services/types/businessUnit';

import { Origin } from '../types/origin';
import { SignInBody, SignInResponse, User } from '../types/user';
import baseApi from './baseApi';

const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signInUser: build.mutation({
      query: (body: SignInBody) => ({
        url: '/auth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SignInResponse) => response,
    }),
    getUser: build.query({
      query: () => ({
        url: '/users/user',
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
    getUserBusinessUnits: build.query({
      query: () => ({
        url: '/user/businessUnits',
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit[]): BusinessUnit[] => response,
    }),
    getBusinessUnitById: build.mutation({
      query: (businessUnitId: number) => ({
        url: `/user/businessUnit/${businessUnitId}`,
        method: 'GET',
      }),
      transformResponse: (response: BusinessUnit): BusinessUnit => response,
    }),
    getBusinessUnitSources: build.query({
      query: (props: { businessUnitId: number | null }) => ({
        url: `/user/businessUnit/sources?businessUnitId=${props.businessUnitId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    getBusinessUnitBrands: build.query({
      query: (props: { businessUnitId: number | null }) => ({
        url: `/user/businessUnit/brands?businessUnitId=${props.businessUnitId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]): string[] => response,
    }),
    updatePassword: build.mutation({
      query: (body: { currentPassword: string; newPassword: string }) => ({
        url: '/user/update-password',
        method: 'PUT',
        body,
      }),
    }),
    getAssignableUsers: build.mutation({
      query: (clientId: number) => ({
        url: `/user/assignable-users/${clientId}`,
        method: 'GET',
      }),
      transformResponse: (
        response: { assignableUsers?: User[]; error?: string } | null
      ): { assignableUsers?: User[]; error?: string } | null => response,
    }),
    assignClientToUser: build.mutation({
      query: (body: { userId: number; clientToAssignId: number }) => ({
        url: '/user/assign-client',
        method: 'PATCH',
        body,
      }),
    }),
    getUsers: build.query({
      query: () => ({
        url: '/user/users',
        method: 'GET',
      }),
      transformResponse: (response: {
        users: (User & { businessUnitNames: string[] })[];
      }): (User & { businessUnitNames: string[] })[] => response.users,
    }),
    handleFirebaseNotificationTokens: build.mutation({
      query: (body: { newNotificationToken?: string | null }) => ({
        url: '/user/handle-firebase-notification-tokens',
        method: 'POST',
        body,
      }),
    }),
    updateName: build.mutation({
      query: (body: { name: string }) => ({
        url: '/user/update-name',
        method: 'PATCH',
        body,
      }),
    }),
    getUserOrigins: build.query({
      query: (props: { businessUnitId: number | null } | null) => ({
        url: `/origins${props && props.businessUnitId ? `?businessUnitId=${props.businessUnitId}` : ''}`,
        method: 'GET',
      }),
      transformResponse: (response: { origins: Origin[] }) => response.origins,
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInUserMutation,
  useGetUserQuery,
  useGetUserBusinessUnitsQuery,
  useGetBusinessUnitByIdMutation,
  useGetBusinessUnitSourcesQuery,
  useUpdatePasswordMutation,
  useGetBusinessUnitBrandsQuery,
  useGetAssignableUsersMutation,
  useAssignClientToUserMutation,
  useGetUsersQuery,
  useHandleFirebaseNotificationTokensMutation,
  useUpdateNameMutation,
  useGetUserOriginsQuery,
} = userApi;

export default userApi;

import React, { FC } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import useCurrentUser from 'hooks/useCurrentUser';

interface Props {
  businessUnitType: 'car_dealership' | 'financial_advisor' | null;
  setBusinessUnitType: (businessUnitType: 'car_dealership' | 'financial_advisor' | null) => void;
}

const BusinessUnitTypeSelector: FC<Props> = ({ businessUnitType, setBusinessUnitType }) => {
  const currentUser = useCurrentUser();

  if (!currentUser?.isAdmin) {
    return null;
  }

  return (
    <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
      <InputLabel id="business-unit-type-select-label">Tipo de Unidad de Negocio</InputLabel>
      <Select
        labelId="business-unit-type-select-label"
        label="Tipo de Unidad de Negocio"
        variant="outlined"
        value={businessUnitType || ''}
        onChange={(e) => setBusinessUnitType(e.target.value as 'car_dealership' | 'financial_advisor' | null)}
        autoWidth
      >
        <MenuItem value="">
          <Typography fontWeight={500}>Todas las unidades de negocio</Typography>
        </MenuItem>
        <MenuItem value="car_dealership">
          <Typography fontWeight={500}>Concesionario</Typography>
        </MenuItem>
        <MenuItem value="financial_advisor">
          <Typography fontWeight={500}>Asesor Financiero</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default BusinessUnitTypeSelector;

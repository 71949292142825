import React from 'react';
import { Link } from 'react-router-dom';

import AlarmIcon from '@mui/icons-material/Alarm';
import SettingsIcon from '@mui/icons-material/Settings';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useGetBusinessUnitsQuery } from 'services/api/businessUnits.api';

const BusinessUnits = () => {
  const { data: businessUnits } = useGetBusinessUnitsQuery(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredBusinessUnits = React.useMemo(() => {
    if (!businessUnits) return [];

    const filtered = [...businessUnits].filter(
      (unit) =>
        unit.company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unit.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filtered.sort((a, b) => a.company.name.localeCompare(b.company.name));
  }, [businessUnits, searchTerm]);

  const businessUnitsByCompanyCount = filteredBusinessUnits?.reduce(
    (acc, businessUnit) => {
      const company = businessUnit.company.name;
      if (!acc[company]) {
        acc[company] = 0;
      }
      acc[company] += 1;
      return acc;
    },
    {} as Record<string, number>
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center" gap={2}>
        <Typography variant="h4">Lista de Empresas</Typography>
        <TextField
          autoFocus
          placeholder="Buscar por empresa o unidad de negocio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          sx={{ minWidth: 400 }}
        />
      </Box>
      <TableContainer
        sx={{ height: 'calc(100vh - 100px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre empresa</TableCell>
              <TableCell>Unidad de negocio</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Demochat</TableCell>
              <TableCell>Modelo AI</TableCell>
              <TableCell>WA Tier</TableCell>
              <TableCell>Gráficos</TableCell>
              <TableCell>Configuración</TableCell>
              <TableCell>Follow Ups</TableCell>
              <TableCell>Demo Chat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBusinessUnits.map((businessUnit, i) => (
              <TableRow key={businessUnit.id}>
                {filteredBusinessUnits[i - 1]?.company.name !== businessUnit.company.name && (
                  <TableCell
                    rowSpan={businessUnitsByCompanyCount ? businessUnitsByCompanyCount[businessUnit.company.name] : 1}
                    align="center"
                  >
                    <b>{businessUnit.company.name}</b>
                  </TableCell>
                )}
                <TableCell>{businessUnit.name}</TableCell>
                <TableCell>{businessUnit.id}</TableCell>
                <TableCell>{businessUnit.phoneNumber}</TableCell>
                <TableCell>{businessUnit.pusherIdentification}</TableCell>
                <TableCell>{businessUnit.openAIModel}</TableCell>
                <TableCell>
                  {businessUnit.phoneNumberTier
                    ?.map((tier) => `${tier.split('T')[0]} ${tier.split(':').at(-1)}`)
                    .join(', ')}
                </TableCell>
                <TableCell>
                  <Link to={`/businessUnit-charts/${businessUnit.id}`}>
                    <Button>
                      <ShowChartIcon />
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/businessUnit-config/${businessUnit.id}`}>
                    <Button>
                      <SettingsIcon />
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/businessUnit-follow-up-config/${businessUnit.id}`}>
                    <Button>
                      <AlarmIcon />
                    </Button>
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/demochat/${businessUnit.pusherIdentification}`}>
                    <Button>
                      <WhatsAppIcon />
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BusinessUnits;

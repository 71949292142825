import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import EditableAttribute from 'pages/Client/EditableAttribute';
import type { FollowUpConfigDraft } from 'services/types/followUpConfig';

interface FollowUpConfigFormAttributesProps {
  followUpConfigDraft: FollowUpConfigDraft;
  isEditing: boolean;
  setFollowUpConfigDraft?: (followUpConfigDraft: FollowUpConfigDraft) => void;
  isCampaign?: boolean;
}

const FollowUpConfigFormAttributes: FC<FollowUpConfigFormAttributesProps> = ({
  followUpConfigDraft,
  isEditing,
  setFollowUpConfigDraft,
  isCampaign,
}) => {
  const [conditionsError, setConditionsError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [newSpecificText, setNewSpecificText] = useState('');

  const onChangeConditionsToSend = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!setFollowUpConfigDraft) return;
    const newConditions = e.target.value;
    try {
      const value = JSON.parse(newConditions) as string;
      setFollowUpConfigDraft({ ...followUpConfigDraft, conditionsToSend: JSON.stringify(value, null, 2) });
      setConditionsError(false);
    } catch (_e) {
      setFollowUpConfigDraft({ ...followUpConfigDraft, conditionsToSend: newConditions });
      setConditionsError(true);
    }
  };

  const onChangeTemplateComponents = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!setFollowUpConfigDraft) return;
    const newTemplateComponents = e.target.value;
    try {
      const value = JSON.parse(newTemplateComponents) as string;
      setFollowUpConfigDraft({ ...followUpConfigDraft, templateComponents: JSON.stringify(value, null, 2) });
      setTemplateError(false);
    } catch (_e) {
      setFollowUpConfigDraft({ ...followUpConfigDraft, templateComponents: newTemplateComponents });
      setTemplateError(true);
    }
  };

  return (
    <Box>
      {!isCampaign && (
        <EditableAttribute
          label="Identificador"
          value={followUpConfigDraft.identifier}
          onChange={(e) =>
            setFollowUpConfigDraft && setFollowUpConfigDraft({ ...followUpConfigDraft, identifier: e.target.value })
          }
          disabled={!isEditing}
          type="text"
        />
      )}
      <EditableAttribute
        label="Activo"
        value={followUpConfigDraft.isActive}
        onChange={(e) =>
          setFollowUpConfigDraft && setFollowUpConfigDraft({ ...followUpConfigDraft, isActive: e.target.checked })
        }
        disabled={!isEditing}
        type="checkbox"
      />

      <Grid item xs={12}>
        <Box>
          <Typography>
            <b>
              {followUpConfigDraft.prompt !== null && 'Prompt'}
              {followUpConfigDraft.specificTexts !== null && 'Textos específicos'}
            </b>
          </Typography>
          {followUpConfigDraft.prompt !== null && (
            <textarea
              name="prompt"
              disabled={!isEditing}
              rows={10}
              cols={150}
              onChange={(e) =>
                setFollowUpConfigDraft && setFollowUpConfigDraft({ ...followUpConfigDraft, prompt: e.target.value })
              }
              value={followUpConfigDraft.prompt}
              style={{
                marginBottom: '8px',
                backgroundColor: '#c9d9c9',
                fontSize: '12px',
              }}
            />
          )}
          {followUpConfigDraft.specificTexts !== null && (
            <>
              <Typography>[{followUpConfigDraft.specificTexts.join(',')}]</Typography>
              {isEditing && (
                <>
                  <TextField onChange={(e) => setNewSpecificText(e.target.value)} value={newSpecificText} />
                  <PrimaryBtn
                    onClick={() => {
                      if (setFollowUpConfigDraft) {
                        setFollowUpConfigDraft({
                          ...followUpConfigDraft,
                          specificTexts: [...(followUpConfigDraft.specificTexts || []), newSpecificText],
                        });
                        setNewSpecificText('');
                      }
                    }}
                    variant="contained"
                  >
                    Agregar
                  </PrimaryBtn>
                  <PrimaryBtn
                    onClick={() => {
                      if (setFollowUpConfigDraft) {
                        setFollowUpConfigDraft({
                          ...followUpConfigDraft,
                          specificTexts: [...(followUpConfigDraft.specificTexts?.slice(0, -1) || [])],
                        });
                        setNewSpecificText('');
                      }
                    }}
                    variant="contained"
                    color="error"
                  >
                    Eliminar
                  </PrimaryBtn>
                </>
              )}
            </>
          )}
        </Box>
      </Grid>

      {followUpConfigDraft.templateName !== null && !isCampaign && (
        <EditableAttribute
          label="Template name"
          value={followUpConfigDraft.templateName}
          onChange={(e) =>
            setFollowUpConfigDraft && setFollowUpConfigDraft({ ...followUpConfigDraft, templateName: e.target.value })
          }
          disabled={!isEditing}
          type="text"
        />
      )}

      <Box display="flex" justifyContent="inline-flex" gap={2}>
        <EditableAttribute
          label="Min wait time (minutes)"
          value={followUpConfigDraft.minMinutesWaitTime}
          onChange={(e) =>
            setFollowUpConfigDraft &&
            setFollowUpConfigDraft({ ...followUpConfigDraft, minMinutesWaitTime: Math.max(10, Number(e.target.value)) })
          }
          disabled={!isEditing}
          type="number"
        />
        <EditableAttribute
          label="Max wait time (minutes)"
          value={followUpConfigDraft.maxMinutesWaitTime}
          onChange={(e) =>
            setFollowUpConfigDraft &&
            setFollowUpConfigDraft({ ...followUpConfigDraft, maxMinutesWaitTime: Math.max(11, Number(e.target.value)) })
          }
          disabled={!isEditing}
          type="number"
        />
      </Box>
      <Box display="flex" justifyContent="inline-flex" gap={2}>
        <EditableAttribute
          label="Min working hour"
          value={followUpConfigDraft.minWorkingHour}
          onChange={(e) =>
            setFollowUpConfigDraft &&
            setFollowUpConfigDraft({
              ...followUpConfigDraft,
              minWorkingHour: Math.min(Math.max(0, Number(e.target.value)), 23),
            })
          }
          disabled={!isEditing}
          type="number"
        />
        <EditableAttribute
          label="Max working hour"
          value={followUpConfigDraft.maxWorkingHour}
          onChange={(e) =>
            setFollowUpConfigDraft &&
            setFollowUpConfigDraft({
              ...followUpConfigDraft,
              maxWorkingHour: Math.min(Math.max(0, Number(e.target.value)), 23),
            })
          }
          disabled={!isEditing}
          type="number"
        />
      </Box>

      <EditableAttribute
        label="Enviar fin de semana"
        value={followUpConfigDraft.sendOnWeekend}
        onChange={(e) =>
          setFollowUpConfigDraft && setFollowUpConfigDraft({ ...followUpConfigDraft, sendOnWeekend: e.target.checked })
        }
        disabled={!isEditing}
        type="checkbox"
      />
      <EditableAttribute
        label="Max consecutivos"
        value={followUpConfigDraft.maxConsecutive}
        onChange={(e) =>
          setFollowUpConfigDraft &&
          setFollowUpConfigDraft({ ...followUpConfigDraft, maxConsecutive: Math.max(1, Number(e.target.value)) })
        }
        disabled={!isEditing}
        type="number"
      />
      <EditableAttribute
        label="Max en conversación"
        value={followUpConfigDraft.maxAmountInConversation}
        onChange={(e) =>
          setFollowUpConfigDraft &&
          setFollowUpConfigDraft({
            ...followUpConfigDraft,
            maxAmountInConversation: Math.max(1, Number(e.target.value)),
          })
        }
        disabled={!isEditing}
        type="number"
      />
      <EditableAttribute
        label="Límite de clientes por ejecución"
        value={followUpConfigDraft.clientsBatchSize || 0}
        onChange={(e) =>
          setFollowUpConfigDraft &&
          setFollowUpConfigDraft({ ...followUpConfigDraft, clientsBatchSize: Math.max(1, Number(e.target.value)) })
        }
        disabled={!isEditing}
        type="number"
      />
      <EditableAttribute
        label="Previous Keep Alive Identifier"
        value={followUpConfigDraft.previousMessageKeepAliveIdentifier || ''}
        onChange={(e) =>
          setFollowUpConfigDraft &&
          setFollowUpConfigDraft({
            ...followUpConfigDraft,
            previousMessageKeepAliveIdentifier: e.target.value === '' ? null : e.target.value,
          })
        }
        disabled={!isEditing}
        type="text"
      />
      <EditableAttribute
        label="Required previous follow ups ids"
        value={followUpConfigDraft.requiredPreviousFollowUpsIds?.join(',') || ''}
        onChange={(e) =>
          setFollowUpConfigDraft &&
          setFollowUpConfigDraft({
            ...followUpConfigDraft,
            requiredPreviousFollowUpsIds: e.target.value === '' ? null : e.target.value.split(',').map(Number),
          })
        }
        disabled={!isEditing}
        type="text"
      />

      <Grid item xs={12}>
        <Box>
          <Typography>
            <b>Conditions to send:</b>
          </Typography>
          <textarea
            name="conditionsToSend"
            disabled={!isEditing}
            rows={8}
            cols={70}
            onChange={(e) => onChangeConditionsToSend(e)}
            value={followUpConfigDraft.conditionsToSend}
            style={{
              backgroundColor: conditionsError ? '#e1c0c0' : '#c9d9c9',
              fontSize: '12px',
            }}
          />
        </Box>
        {followUpConfigDraft.templateName !== null && (
          <Box>
            {isEditing && (
              <Typography>
                <b>Template components:</b> Pueden ser atributos del cliente por ejemplo:
                <i>timeOf.client.visitDate</i> o <i>client.branchOffice</i>
              </Typography>
            )}
            <textarea
              name="templateComponents"
              disabled={!isEditing}
              rows={8}
              cols={70}
              onChange={(e) => onChangeTemplateComponents(e)}
              value={followUpConfigDraft.templateComponents || ''}
              style={{
                backgroundColor: templateError ? '#e1c0c0' : '#c9d9c9',
                fontSize: '12px',
              }}
            />
          </Box>
        )}
        {followUpConfigDraft.functionName !== null && (
          <EditableAttribute
            label="Function name"
            value={followUpConfigDraft.functionName}
            onChange={(e) =>
              setFollowUpConfigDraft &&
              setFollowUpConfigDraft({
                ...followUpConfigDraft,
                functionName: e.target.value === '' ? null : e.target.value,
              })
            }
            disabled={!isEditing}
            type="text"
          />
        )}
      </Grid>
    </Box>
  );
};

FollowUpConfigFormAttributes.defaultProps = {
  setFollowUpConfigDraft: undefined,
  isCampaign: false,
};

export default FollowUpConfigFormAttributes;

import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import {
  Client,
  ClientWithLastMessage,
  EditableAdminClientAttributes,
  EditableClientAttributes,
  FinancialAdvisorIndividualClientMetrics,
  Lead,
} from 'services/types/client';

import { TemplateResponse } from '../types/message';
import baseApi from './baseApi';

const clientsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query({
      query: (params: {
        paginationModel: { page: number; pageSize: number };
        filterModel: GridFilterModel;
        sortModel: GridSortModel;
        searchTerm?: string;
        businessUnitId?: number;
        businessUnitType?: 'car_dealership' | 'financial_advisor' | null;
      }) => ({
        url: `/clients?paginationModel=${JSON.stringify(params.paginationModel)}&filterModel=${JSON.stringify(
          params.filterModel
        )}&sortModel=${JSON.stringify(params.sortModel)}&searchTerm=${params.searchTerm}&businessUnitId=${
          (params.businessUnitId || 0) > 0 ? params.businessUnitId : ''
        }&businessUnitType=${params.businessUnitType || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: { clients: Client[]; total: number }) => {
        return {
          clients: response.clients,
          total: response.total,
        };
      },
    }),
    getClientsSources: build.query({
      query: (params: { businessUnitId?: number }) => ({
        url: `/clients/sources?businessUnitId=${params.businessUnitId || ''}`,
        method: 'GET',
      }),
      transformResponse: (response: string[]) => response,
    }),
    getClientsFullList: build.mutation({
      query: () => ({
        url: '/clients/fullList',
        method: 'GET',
      }),
      transformResponse: (response: Client[]) => response,
    }),
    getClient: build.query({
      query: (id: number) => ({
        url: `/clients/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Client): Client => response,
    }),
    getTemplates: build.query({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/templates`,
        method: 'GET',
      }),
      transformResponse: (response: TemplateResponse) => response,
    }),
    getCreditStatus: build.mutation({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/credit-status`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
    }),

    updateEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableClientAttributes }) => ({
        url: `/clients/${props.id}/editable-attributes`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    updateAdminEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableAdminClientAttributes }) => ({
        url: `/clients/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    adminSearchClientByMessage: build.query({
      query: (query: string) => ({
        url: '/admin/clients/search',
        method: 'GET',
        params: {
          query,
        },
      }),
      transformResponse: (response: ClientWithLastMessage[]) => response,
    }),
    uploadClientToCRM: build.mutation({
      query: ({ clientId, businessUnitId }: { clientId: number; businessUnitId: number }) => ({
        url: '/CRMUploads/upload-client-to-CRM',
        method: 'POST',
        body: { clientId, businessUnitId },
      }),
      transformResponse: (response) => response,
    }),
    getFinancialAdvisorIndividualClientMetrics: build.mutation({
      query: (userId: number) => ({
        url: `/clients/${userId}/financial-advisor-metrics`,
        method: 'GET',
      }),
      transformResponse: (response: FinancialAdvisorIndividualClientMetrics) => response,
    }),
    getClientLeads: build.query({
      query: (clientId: number) => ({
        url: `/clients/${clientId}/leads`,
        method: 'GET',
      }),
      transformResponse: (response: { leads: Lead[] }) => response,
    }),
    adminUploadClientsFromCSV: build.mutation({
      query: (props: {
        businessUnitId: number;
        outboundCampaignId: string;
        source: string;
        mappedHeaders: { headerName: string; attrCliente: string }[];
        separator: string;
        file: File;
      }) => {
        const formData = new FormData();
        formData.append('file', props.file);
        formData.append('businessUnitId', props.businessUnitId.toString());
        formData.append('outboundCampaignId', props.outboundCampaignId);
        formData.append('source', props.source);
        formData.append('mappedHeaders', JSON.stringify(props.mappedHeaders));
        formData.append('separator', props.separator);
        return {
          url: '/admin/campaigns/upload-outbound-campaign-clients',
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response: { createdClients: number[]; updatedClients: number[] }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientQuery,
  useGetClientsQuery,
  useGetClientsFullListMutation,
  useGetTemplatesQuery,
  useGetCreditStatusMutation,
  useUpdateEditableAttributesMutation,
  useUpdateAdminEditableAttributesMutation,
  useAdminSearchClientByMessageQuery,
  useUploadClientToCRMMutation,
  useGetFinancialAdvisorIndividualClientMetricsMutation,
  useGetClientLeadsQuery,
  useAdminUploadClientsFromCSVMutation,
  useGetClientsSourcesQuery,
} = clientsApi;

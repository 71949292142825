import { OldAnalysis, Analysis } from 'services/types/analysis';

import baseApi from './baseApi';

const mapOldAnalysisToNewAnalysis = (analysis: OldAnalysis): Analysis => ({
  ...analysis,
  history: analysis.history.map((message) => ({
    ...message,
    toolCalls: (message.toolCalls || []).concat(message.tool_calls || []),
    toolCallId: message.toolCallId || message.tool_call_id,
  })),
});

const businessUnitsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAnalyses: build.query({
      query: () => ({
        url: '/userAnalyses/list-businessUnit-analyses',
        method: 'GET',
      }),
      transformResponse: (response: Analysis[]) => response.map(mapOldAnalysisToNewAnalysis),
    }),
    getAnalysis: build.query({
      query: (id: number) => ({
        url: `/userAnalyses/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Analysis) => mapOldAnalysisToNewAnalysis(response),
    }),
    addMessageToAnalysis: build.mutation({
      query: (props: { message: string; id: number }) => ({
        url: `userAnalyses/${props.id}`,
        method: 'PATCH',
        body: { newMessage: props.message },
      }),
      transformResponse: (response: Analysis) => mapOldAnalysisToNewAnalysis(response),
    }),
    editTitleToAnalysis: build.mutation({
      query: (props: { query: string; id: number }) => ({
        url: `userAnalyses/${props.id}`,
        method: 'PATCH',
        body: { query: props.query },
      }),
      transformResponse: (response: Analysis) => mapOldAnalysisToNewAnalysis(response),
    }),
    createAnalysis: build.mutation({
      query: (props: { query: string; businessUnitId?: number }) => ({
        url: 'userAnalyses',
        method: 'POST',
        body: { query: props.query, businessUnitId: props.businessUnitId },
      }),
      transformResponse: (response: Analysis) => mapOldAnalysisToNewAnalysis(response),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAnalysesQuery,
  useGetAnalysisQuery,
  useAddMessageToAnalysisMutation,
  useEditTitleToAnalysisMutation,
  useCreateAnalysisMutation,
} = businessUnitsApi;

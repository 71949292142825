import React, { FC, SyntheticEvent } from 'react';

import { TextField, Checkbox, FormControl, Autocomplete } from '@mui/material';

import { useGetBusinessUnitBrandsQuery } from 'services/api/user.api';

interface BrandProps {
  brands: string[];
  setBrands: (brands: string[]) => void;
  businessUnitId: number | null;
}

const BrandsSelector: FC<BrandProps> = ({ brands, setBrands, businessUnitId }) => {
  const { data: brandsData } = useGetBusinessUnitBrandsQuery({ businessUnitId });

  const definedBrands = brandsData?.filter((brand) => !!brand) || [];

  const handleSelectAll = () => {
    setBrands(definedBrands);
  };

  const handleSelectNone = () => {
    setBrands([]);
  };

  const handleChange = (event: SyntheticEvent, value: string[]) => {
    if (value.includes('Seleccionar todos')) {
      handleSelectAll();
    } else if (value.includes('Seleccionar ninguno')) {
      handleSelectNone();
    } else {
      setBrands(value);
    }
  };

  const optionsWithSelectAllNone = [
    brands.length === definedBrands.length ? 'Seleccionar ninguno' : 'Seleccionar todos',
    ...definedBrands,
  ];

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <Autocomplete
        multiple
        options={optionsWithSelectAllNone}
        value={brands}
        onChange={handleChange}
        disableCloseOnSelect
        renderInput={(params) => <TextField {...params} label="Brand" />}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option}
          </li>
        )}
        getOptionLabel={(option) => option || '--unknown--'}
        isOptionEqualToValue={(option, value) => option === value}
      />
    </FormControl>
  );
};

export default BrandsSelector;

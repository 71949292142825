import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';

import SearchElement from 'components/common/SearchElement';
import {
  useGetCarModelsQuery,
  useCreateCarModelMutation,
  useUpdateCarModelMutation,
  useDeleteCarModelMutation,
} from 'services/api/carModels.api';

import { CarModel, CarModelDraft, CarType, CarTypes, getAvailableSubCategories } from '../../services/types/carModel';

const CarModels = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [formData, setFormData] = useState<CarModelDraft>({
    brand: '',
    name: '',
    allowedNames: [],
    category: null,
    subCategory: null,
  });

  const navigate = useNavigate();
  const { data: carModels, refetch: refetchCarModels } = useGetCarModelsQuery(null);
  const [createCarModel] = useCreateCarModelMutation();
  const [updateCarModel] = useUpdateCarModelMutation();
  const [deleteCarModel] = useDeleteCarModelMutation();

  const handleClose = () => {
    setOpenDialog(false);
    setEditingId(null);
    setFormData({
      brand: '',
      name: '',
      allowedNames: [],
      category: null,
      subCategory: null,
    });
  };

  const isFormValid =
    formData.brand && formData.name && formData.allowedNames.length > 0 && formData.category && formData.subCategory;

  const handleSubmit = () => {
    if (isFormValid) {
      if (editingId) {
        updateCarModel({ id: editingId, body: formData }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error saving car model:', error);
        });
      } else {
        createCarModel(formData).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error saving car model:', error);
        });
      }
      refetchCarModels().catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error refetching car models:', error);
      });
      handleClose();
    }
  };

  const handleEdit = (row: CarModel) => {
    setEditingId(row.id);
    setFormData({
      brand: row.brand,
      name: row.name,
      allowedNames: row.allowedNames,
      category: row.category,
      subCategory: row.subCategory,
    });
    setOpenDialog(true);
  };

  const handleDelete = (id: number) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('¿Estás seguro de que quieres eliminar este modelo?')) {
      deleteCarModel(id).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error deleting car model:', error);
      });
      refetchCarModels().catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error refetching car models:', error);
      });
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'brand', headerName: 'Marca', width: 130 },
    { field: 'name', headerName: 'Nombre', width: 130 },
    {
      field: 'allowedNames',
      headerName: 'Nombres permitidos',
      width: 300,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', height: '100%', flexWrap: 'wrap' }}>
          {Array.isArray(params.value) &&
            params.value.map((name: string) => <Chip key={name} label={name} size="small" />)}
        </Box>
      ),
    },
    { field: 'category', headerName: 'Categoría', width: 130 },
    { field: 'subCategory', headerName: 'Subcategoría', width: 130 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      getActions: (params: { row: CarModel }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
          key={params.row.id}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDelete(params.row.id)}
          key={params.row.id}
        />,
      ],
    },
  ];

  let filteredCarModels = carModels;
  if (searchTerm.length > 0) {
    filteredCarModels = carModels?.filter(
      (model) =>
        model.brand.toLowerCase().includes(searchTerm) ||
        model.name.toLowerCase().includes(searchTerm) ||
        model.category?.toLowerCase().includes(searchTerm) ||
        model.subCategory?.toLowerCase().includes(searchTerm)
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="h4" pr={2}>
            Modelos de Autos
          </Typography>
          <Button onClick={() => navigate('/cars')}>Ver Autos</Button>
        </Box>
        <SearchElement searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Agregar Modelo
        </Button>
      </Box>
      <Box sx={{ height: 'calc(100vh - 100px)', color: 'black' }}>
        {carModels && <DataGrid rows={filteredCarModels} columns={columns} />}
      </Box>

      <Dialog open={openDialog} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editingId ? 'Editar Modelo' : 'Nuevo Modelo'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Marca"
              value={formData.brand}
              onChange={(e) => setFormData({ ...formData, brand: e.target.value })}
            />
            <TextField
              label="Nombre"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <TextField
              label="Nombres permitidos"
              value={formData.allowedNames.join(', ')}
              onChange={(e) =>
                setFormData({ ...formData, allowedNames: e.target.value.split(',').map((s) => s.trim()) })
              }
              helperText="Separar con comas"
            />
            <FormControl>
              <InputLabel id="select-category">Categoría</InputLabel>
              <Select
                id="select-category"
                value={formData.category || ''}
                onChange={(e) => setFormData({ ...formData, category: e.target.value as CarType, subCategory: null })}
                label="Categoría"
              >
                {CarTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formData.category && (
              <FormControl>
                <InputLabel id="select-subcategory">Subcategoría</InputLabel>
                <Select
                  label="Subcategoría"
                  value={formData.subCategory}
                  onChange={(e) => setFormData({ ...formData, subCategory: e.target.value })}
                >
                  {getAvailableSubCategories(formData.category)?.map((subCategory) => (
                    <MenuItem key={subCategory} value={subCategory}>
                      {subCategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CarModels;

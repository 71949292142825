import React, { FC, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import { GridColDef, GridFilterItem, GridFilterModel } from '@mui/x-data-grid';

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onFilterChange: (items: GridFilterItem[]) => void;
  columns: GridColDef[];
  filterModel: GridFilterModel;
  sources: string[];
}

const FilterModal: FC<FilterModalProps> = ({ open, onClose, onFilterChange, columns, filterModel, sources }) => {
  const [items, setItems] = useState<GridFilterItem[]>(filterModel.items);

  const handleFieldChange = (index: number, field: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, field } : item)));
  };
  const handleOperatorChange = (index: number, operator: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, operator } : item)));
  };
  const handleValueChange = (index: number, value: string) => {
    setItems(items.map((item, i) => (i === index ? { ...item, value } : item)));
  };
  const addFilter = () => {
    setItems(items.concat({ field: '', operator: '', value: '' }));
  };
  const removeFilter = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const isSomeItemEmpty = items.some((item) => item.field === '' || item.operator === '' || item.value === '');

  const getTooltipTitle = () => {
    if (items.length === 0) {
      return 'Agrega un filtro para empezar';
    }
    if (isSomeItemEmpty) {
      return 'Completa todos los campos para aplicar el filtro';
    }
    return '';
  };

  const handleApply = () => {
    if (isSomeItemEmpty) {
      return;
    }
    onFilterChange(items);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '700px' } }}>
      <DialogTitle>Filtrar</DialogTitle>
      <DialogContent>
        {items.length > 0 ? (
          <Grid container>
            <Grid item xs={3.5}>
              Campo
            </Grid>
            <Grid item xs={3.5}>
              Operador
            </Grid>
            <Grid item xs={3.5}>
              Valor
            </Grid>
            <Grid item xs={1}>
              Eliminar
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1">Agrega un filtro para empezar</Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {items.map((item, index) => (
            <Grid container key={item.field} sx={{ mb: 2, width: '100%' }}>
              <Grid item xs={3.5} pr={1}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id={`field-label-${index}`}>Campo</InputLabel>
                  <Select
                    labelId={`field-label-${index}`}
                    value={item.field}
                    onChange={(e) => handleFieldChange(index, e.target.value)}
                    sx={{ width: '100%' }}
                  >
                    {columns.map((column) => (
                      <MenuItem key={column.field} value={column.field}>
                        {column.headerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5} pr={1}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id={`operator-label-${index}`}>Operador</InputLabel>
                  <Select
                    labelId={`operator-label-${index}`}
                    value={item.operator}
                    onChange={(e) => handleOperatorChange(index, e.target.value)}
                    sx={{ width: '100%' }}
                  >
                    {item.field === 'source'
                      ? [
                          <MenuItem key="contains" value="contains">
                            Contiene
                          </MenuItem>,
                        ]
                      : [
                          <MenuItem key="contains" value="contains">
                            Contiene
                          </MenuItem>,
                          <MenuItem key="equals" value="equals">
                            Igual a
                          </MenuItem>,
                          <MenuItem key="startsWith" value="startsWith">
                            Comienza con
                          </MenuItem>,
                          <MenuItem key="endsWith" value="endsWith">
                            Termina con
                          </MenuItem>,
                          <MenuItem key="isEmpty" value="isEmpty">
                            Está vacío
                          </MenuItem>,
                        ]}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5} pr={1}>
                {item.field === 'source' ? (
                  <Select
                    multiple
                    value={(item.value as string).split(',')}
                    onChange={(e) => handleValueChange(index, (e.target.value as string[]).join(','))}
                    placeholder="Orígenes"
                    label="Orígenes"
                    sx={{ width: '100%' }}
                  >
                    {sources.map((source) => (
                      <MenuItem key={source} value={source}>
                        {source}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    label="Valor"
                    value={String(item.value) || ''}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                  />
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => removeFilter(index)}>
                  <RemoveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
        <IconButton onClick={addFilter}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Tooltip title={getTooltipTitle()}>
          <div>
            <Button onClick={handleApply} disabled={isSomeItemEmpty}>
              Aplicar
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;

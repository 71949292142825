import React, { useState, useEffect } from 'react';

import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Save from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useEditBusinessUnitMessagesToNotifyMutation } from 'services/api/businessUnits.api';
import type { BusinessUnit, MessageToNotify } from 'services/types/businessUnit';

interface BusinessUnitAlertsEditorProps {
  businessUnit: BusinessUnit;
}

const BusinessUnitAlertsEditor = ({ businessUnit }: BusinessUnitAlertsEditorProps) => {
  const [messagesToNotifyDraft, setMessagesToNotifyDraft] = useState<MessageToNotify[]>(
    businessUnit.messagesToNotify || []
  );

  const [editBusinessUnitMessagesToNotify] = useEditBusinessUnitMessagesToNotifyMutation();

  useEffect(() => {
    setMessagesToNotifyDraft(businessUnit.messagesToNotify || []);
  }, [businessUnit]);

  const handleAddAlert = () => {
    setMessagesToNotifyDraft([...messagesToNotifyDraft, { description: '', examples: [], slackChannelId: '' }]);
  };

  const handleDeleteAlert = (index: number) => {
    const updatedAlerts = [...messagesToNotifyDraft];
    updatedAlerts.splice(index, 1);
    setMessagesToNotifyDraft(updatedAlerts);
  };

  const handleAlertChange = (index: number, key: keyof MessageToNotify, value: string) => {
    const updatedAlerts = [...messagesToNotifyDraft];
    updatedAlerts[index] = { ...updatedAlerts[index], [key]: value };
    setMessagesToNotifyDraft(updatedAlerts);
  };

  const handleAddExample = (alertIndex: number) => {
    const updatedAlerts = messagesToNotifyDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: [...alert.examples, ''],
        };
      }
      return alert;
    });
    setMessagesToNotifyDraft(updatedAlerts);
  };

  const handleExampleChange = (alertIndex: number, exampleIndex: number, value: string) => {
    const tempExamples = [...messagesToNotifyDraft[alertIndex].examples];
    tempExamples[exampleIndex] = value;
    const updatedAlerts = messagesToNotifyDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: tempExamples,
        };
      }
      return alert;
    });
    setMessagesToNotifyDraft(updatedAlerts);
  };

  const handleDeleteExample = (alertIndex: number, exampleIndex: number) => {
    const updatedAlerts = messagesToNotifyDraft.map((alert, idx) => {
      if (idx === alertIndex) {
        return {
          ...alert,
          examples: alert.examples.filter((_, exIdx) => exIdx !== exampleIndex),
        };
      }
      return alert;
    });
    setMessagesToNotifyDraft(updatedAlerts);
  };

  const handleSaveAlertsConfig = () => {
    const filteredAlertsConfig = messagesToNotifyDraft
      .filter((alert) => alert.description && alert.examples.length > 0)
      .map((alert) => ({
        ...alert,
        slackChannelId: alert.slackChannelId || undefined,
      }));
    if (businessUnit) {
      editBusinessUnitMessagesToNotify({
        id: businessUnit.id,
        messagesToNotify: filteredAlertsConfig,
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error saving alerts config', error);
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} my={3}>
      <Typography variant="h6">Alertas</Typography>
      RECUERDA GUARDAR las alertas al terminar de editar y probar.
      {messagesToNotifyDraft.map((alert, alertIndex) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`alert-${alertIndex}`}
          sx={{
            border: '2px solid #a1a1a1',
            borderRadius: '8px',
            padding: '25px',
            mb: 2,
            backgroundColor: '#f6f6f6',
          }}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6">Alerta {alertIndex + 1}</Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
                <PrimaryBtn
                  startIcon={<Delete />}
                  onClick={() => handleDeleteAlert(alertIndex)}
                  variant="contained"
                  color="error"
                  sx={{ maxWidth: 200 }}
                >
                  Eliminar Alerta
                </PrimaryBtn>
              </Grid>
            </Grid>
            <TextField
              label="Descripción"
              value={alert.description}
              onChange={(e) => handleAlertChange(alertIndex, 'description', e.target.value)}
              required
              fullWidth
              multiline
              rows={2}
              InputProps={{
                inputComponent: 'textarea',
                inputProps: {
                  style: { resize: 'both', overflow: 'auto' },
                },
              }}
            />
            <TextField
              label="ID Canal de Slack"
              value={alert.slackChannelId}
              onChange={(e) => handleAlertChange(alertIndex, 'slackChannelId', e.target.value)}
            />
          </Box>
          <Box mt={2} display="flex" flexDirection="column">
            Ejemplos:
            {alert.examples.map((example, exampleIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`example-${exampleIndex}`} display="flex" alignItems="center" mt={1}>
                <TextField
                  value={example}
                  onChange={(e) => handleExampleChange(alertIndex, exampleIndex, e.target.value)}
                  fullWidth
                  sx={{ '& input': { padding: '10px 15px' } }}
                />
                <IconButton onClick={() => handleDeleteExample(alertIndex, exampleIndex)} color="error">
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => handleAddExample(alertIndex)}
              startIcon={<Add />}
              variant="outlined"
              color="primary"
              style={{ marginTop: 10, maxWidth: 200 }}
            >
              Agregar Ejemplo
            </Button>
          </Box>
        </Box>
      ))}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
        <PrimaryBtn
          startIcon={<Add />}
          onClick={handleAddAlert}
          variant="contained"
          color="primary"
          sx={{ maxWidth: 200 }}
        >
          Agregar Alerta
        </PrimaryBtn>

        <PrimaryBtn
          startIcon={<Save />}
          onClick={handleSaveAlertsConfig}
          variant="contained"
          color="primary"
          sx={{ marginLeft: 2, maxWidth: 200 }}
        >
          Guardar Alertas
        </PrimaryBtn>
      </div>
    </Box>
  );
};

export default BusinessUnitAlertsEditor;

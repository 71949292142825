import React, { FC, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useGetUserOriginsQuery } from 'services/api/user.api';

interface Props {
  originId: number | null;
  setOriginId: (originId: number) => void;
  selectItemIfOnlyOne?: boolean;
  showAllOption?: boolean;
  businessUnitId: number | null;
}

const OriginSelector: FC<Props> = ({ originId, setOriginId, selectItemIfOnlyOne, showAllOption, businessUnitId }) => {
  const { data: origins } = useGetUserOriginsQuery(businessUnitId ? { businessUnitId } : null);

  useEffect(() => {
    if (selectItemIfOnlyOne && origins && origins.length === 1) {
      setOriginId(origins[0].id);
    }
  }, [origins, selectItemIfOnlyOne, setOriginId]);

  if (!origins) {
    return null;
  }

  return (
    <FormControl sx={{ minWidth: 250, paddingRight: 1 }}>
      <InputLabel id="origin-select-label">Origen</InputLabel>
      <Select
        label="Origen"
        variant="outlined"
        onChange={(e) => setOriginId(Number(e.target.value))}
        value={originId || ''}
        autoWidth
      >
        {showAllOption && <MenuItem value={-1}>Todos los orígenes</MenuItem>}
        {origins.map((origin) => (
          <MenuItem key={origin.id} value={origin.id}>
            <Typography fontWeight={500}>{origin.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

OriginSelector.defaultProps = {
  selectItemIfOnlyOne: false,
  showAllOption: false,
};

export default OriginSelector;

import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useRollbarPerson } from '@rollbar/react';
import useCurrentUser from 'hooks/useCurrentUser';

import { checkIfTokenIsValid } from '../authStorage';
import routes, { Route as RouteType, Role } from './routes';

interface Props {
  route: RouteType;
}

const AuthRoute: FC<Props> = ({ route }) => {
  const { component: Component, path, private: privateRoute } = route;
  const user = useCurrentUser();
  const isAdmin = !!user?.isAdmin;
  const isOriginAdmin = !!user?.isOriginAdmin;
  const isBusinessUnitAdmin = isAdmin || (!!user?.canAssignClient && !user?.isAssignable && !isOriginAdmin);
  const isBranchManager = isBusinessUnitAdmin || (!!user?.canAssignClient && !!user?.isAssignable && !isOriginAdmin);
  const isSeller = !isBranchManager && !isBusinessUnitAdmin && !isAdmin && !isOriginAdmin;

  const getUserRoles = (): Role[] => {
    const roles: Role[] = [];
    if (isAdmin) roles.push('admin');
    if (isBusinessUnitAdmin) roles.push('businessUnitAdmin');
    if (isBranchManager) roles.push('branchManager');
    if (isSeller) roles.push('seller');
    if (isOriginAdmin) roles.push('originAdmin');
    return roles;
  };

  const hasRequiredRole = (requiredRoles?: Role[]): boolean => {
    if (!requiredRoles) return true;
    const userRoles = getUserRoles();
    return userRoles.some((role) => requiredRoles.includes(role));
  };

  if (user) {
    useRollbarPerson({
      id: user.id,
      email: user.email,
    });
  }
  const getValidRedirectUrl = (redirectUrl: string): string => {
    // the redirectUrl could be something like: /redirect-path&param=true for this url to work and keep the params
    // the first & needs to be replaced with ?
    const redirectUrlPath = redirectUrl.split('?')[0].split('&')[0];
    const validRedirectUrl = Object.values(routes).some(
      (routeValue) => routeValue.path === redirectUrlPath || routeValue.regex?.test(redirectUrlPath)
    );
    const validUrl = validRedirectUrl ? redirectUrl.replace('&', '?') : '/';
    // If the user is origin admin, redirect to reports, in all other cases redirect to clients
    if (!validUrl || validUrl === '/') {
      return isOriginAdmin ? '/reports' : '/clients';
    }
    return validUrl;
  };
  const userIsValid = checkIfTokenIsValid(user);
  if (userIsValid && path === '/sign-in') {
    // Adds the text after '?redirect=/'
    const redirectUrl = `/${window.location.search.slice(11)}`;
    return <Navigate to={getValidRedirectUrl(redirectUrl)} />;
  }
  if (privateRoute && !userIsValid) {
    const signInPath = `/sign-in?redirect=${window.location.pathname}${window.location.search}`;
    return <Navigate to={signInPath} />;
  }
  if (privateRoute && userIsValid && route.role) {
    if (!hasRequiredRole(route.role)) {
      return <Navigate to="/unauthorized" />;
    }
    return <Component />;
  }
  return <Component />;
};

export default AuthRoute;

import type { BaseModel } from './baseModel';

export const CarTypes = ['AUTOMOVIL', 'CAMIONETA', 'SUV', 'FURGON', 'SEDAN'] as const;
export type CarType = (typeof CarTypes)[number];
export const CarSubCategories = [
  'CHEAP',
  'VANS',
  'HYBRID',
  'MINIVAN',
  'MEDIUMSIZE',
  'MEDIUMPRICE',
  'EXPENSIVE',
  'NORMAL',
  'SMALL',
  'OUTDOOR',
  'BIG',
] as const;
export type CarSubCategory = (typeof CarSubCategories)[number];

export const getAvailableSubCategories = (category: CarType) => {
  if (category === 'AUTOMOVIL') {
    return ['HYBRID', 'MEDIUMPRICE', 'EXPENSIVE', 'CHEAP'];
  }
  if (category === 'CAMIONETA') {
    return ['MEDIUMSIZE', 'SMALL', 'BIG'];
  }
  if (category === 'SUV') {
    return ['MINIVAN', 'NORMAL', 'SMALL', 'OUTDOOR', 'HYBRID'];
  }
  if (category === 'FURGON') {
    return ['SMALL', 'BIG', 'VANS'];
  }
  if (category === 'SEDAN') {
    return ['MEDIUMPRICE', 'EXPENSIVE', 'CHEAP'];
  }
  return null;
};

export type ExternalIds = {
  astaraId?: string;
  salfaId?: string;
};

export interface CarModelDraft {
  brand: string;
  name: string;
  allowedNames: string[];
  category: CarType | null;
  subCategory: string | null;
  externalIds?: ExternalIds | null;
}

export interface CarModel extends BaseModel, CarModelDraft {}

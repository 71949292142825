import type { OpenAIFunction } from 'services/types/message';

import baseApi from './baseApi';

type MessageToStore = {
  messageId?: string;
  text?: string;
  role?: 'assistant' | 'system' | 'user' | 'tool';
  AIUsage?: object;
  functionName?: string;
  functionArguments?: string;
  toolCallId?: string;
  toolCalls?: {
    toolCallId: string;
    functionName: string;
    functionArguments: string;
  }[];
};

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRealtimeVoiceSettings: build.mutation({
      query: (props: { companyIdentifier: string; recaptchaToken?: string }) => ({
        url: '/realtimeVoice/settings',
        method: 'POST',
        body: {
          companyIdentifier: props.companyIdentifier,
        },
      }),
      transformResponse: (response: {
        tools: { type: 'function'; function: OpenAIFunction }[];
        input_audio_transcription: { model: string };
      }) => response,
    }),
    createRealtimeVoiceClient: build.mutation({
      query: (props: { companyIdentifier: string; recaptchaToken: string }) => ({
        url: '/realtimeVoice/clients',
        method: 'POST',
        body: {
          companyIdentifier: props.companyIdentifier,
          recaptchaToken: props.recaptchaToken,
        },
      }),
      transformResponse: (response: { id: string }) => response,
    }),
    storeMessage: build.mutation({
      query: (props: { message: MessageToStore; clientPhoneIdentifier: string }) => ({
        url: '/realtimeVoice/storeMessage',
        method: 'POST',
        body: {
          message: props.message,
          clientPhoneIdentifier: props.clientPhoneIdentifier,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useCreateRealtimeVoiceClientMutation, useGetRealtimeVoiceSettingsMutation, useStoreMessageMutation } =
  publicAccessApi;

import React, { FC, useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import { PrimaryBtn } from 'components/UI/Buttons';
import InfoDialog from 'components/UI/InfoDialog';
import FollowUpConfigFormAttributes from 'pages/FollowUpConfig/FollowUpConfigFormAttributes';
import { useAdminGetBusinessUnitTemplatesMutation } from 'services/api/businessUnits.api';
import { useCreateFollowUpMutation } from 'services/api/followUpConfig.api';
import { FollowUpConfigDraft } from 'services/types/followUpConfig';
import { TemplateResponse } from 'services/types/message';

const getTemplateBodyParameters = (template: TemplateResponse[number] | null) => {
  if (!template) return null;
  const bodyComponent = template.components.find((component) => component.type === 'BODY');
  if (bodyComponent && bodyComponent.example && bodyComponent.example.body_text) {
    return JSON.stringify(
      {
        bodyComponents: bodyComponent.example.body_text[0],
      },
      null,
      2
    );
  }
  return null;
};

interface FollowUpConfigFormProps {
  businessUnitId: number;
  onSuccess: () => void;
  setOpen: (open: boolean) => void;
}

const FollowUpConfigForm: FC<FollowUpConfigFormProps> = ({ businessUnitId, onSuccess, setOpen }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse[number] | null>(null);
  const [outboundCampaignId, setOutboundCampaignId] = useState<string | null>(null);
  const [newFollowUpConfig, setNewFollowUpConfig] = useState<FollowUpConfigDraft>({
    businessUnitId,
    templateName: selectedTemplate?.name || null,
    minMinutesWaitTime: 10,
    maxMinutesWaitTime: 60,
    minWorkingHour: 9,
    maxWorkingHour: 18,
    sendOnWeekend: true,
    identifier: outboundCampaignId || '',
    maxConsecutive: 1,
    maxAmountInConversation: 1,
    previousMessageKeepAliveIdentifier: null,
    requiredPreviousFollowUpsIds: null,
    conditionsToSend: JSON.stringify(
      {
        equal: [{ field: 'outboundCampaignId', value: outboundCampaignId }],
      },
      null,
      2
    ),
    templateComponents: getTemplateBodyParameters(selectedTemplate),
    isActive: true,
    clientsBatchSize: 1,
    functionName: null,
    prompt: null,
    specificTexts: null,
  });

  const [isError, setIsError] = useState(false);

  const [getBusinessUnitTemplates, { data: templates }] = useAdminGetBusinessUnitTemplatesMutation();
  const [createFollowUpConfig, { isError: isCreateError, error: createError, isSuccess: isCreateSuccess }] =
    useCreateFollowUpMutation();

  useEffect(() => {
    getBusinessUnitTemplates(businessUnitId).catch((err) => console.error(err)); // eslint-disable-line no-console
  }, [businessUnitId]);

  useEffect(() => {
    if (isCreateError) {
      setIsError(true);
    }
  }, [isCreateError]);

  useEffect(() => {
    if (isCreateSuccess) {
      onSuccess();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    setNewFollowUpConfig({
      ...newFollowUpConfig,
      templateName: selectedTemplate?.name || null,
      templateComponents: getTemplateBodyParameters(selectedTemplate),
    });
  }, [selectedTemplate]);

  useEffect(() => {
    setNewFollowUpConfig({
      ...newFollowUpConfig,
      identifier: outboundCampaignId || '',
      conditionsToSend: JSON.stringify(
        {
          equal: [{ field: 'outboundCampaignId', value: outboundCampaignId }],
        },
        null,
        2
      ),
    });
  }, [outboundCampaignId]);

  return (
    <Box width="50%">
      {/* TODO: Improve alert, it seems like an error */}
      <AlertDialog
        openDialog={isError}
        setOpenDialog={setIsError}
        header={`Error al ${createError ? 'crear' : 'editar'} follow up`}
        msg={JSON.stringify(createError)}
        cancelMsg="Cerrar"
      />
      {isCreateSuccess && <InfoDialog header="Follow up creado!" msg="Follow up fue creado correctamente" />}
      <Box width="60%">
        <Box display="flex" alignItems="center">
          <PrimaryBtn onClick={() => setOpen(false)}>Volver</PrimaryBtn>
          <Typography variant="h6">Crear follow up para campaña</Typography>
        </Box>
        <Typography pb={1} variant="body1">
          Define un identificador para la campaña, se utilizará el mismo para el follow up config:
        </Typography>
        <TextField
          label="OutboundCampaignId"
          value={outboundCampaignId || ''}
          onChange={(e) => setOutboundCampaignId(e.target.value)}
          type="text"
          required
          fullWidth
        />
        <Typography variant="body1" py={1}>
          Asegúrate de que si el template tiene parámetros, que solamente tenga en el body
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="select-template">Seleccionar plantilla</InputLabel>
          <Select
            id="select-template"
            value={selectedTemplate?.name || ''}
            label="Seleccionar plantilla"
            required
            onChange={(e) => {
              const targetTemplate = templates?.find((template) => template.name === e.target.value);
              if (targetTemplate) {
                setSelectedTemplate(targetTemplate);
              }
            }}
          >
            {templates?.map((template) => (
              <MenuItem key={template.id} value={template.name}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {newFollowUpConfig && (
        <FollowUpConfigFormAttributes
          followUpConfigDraft={newFollowUpConfig}
          setFollowUpConfigDraft={setNewFollowUpConfig}
          isEditing
          isCampaign
        />
      )}
      <PrimaryBtn
        onClick={() => {
          if (!newFollowUpConfig) return;
          createFollowUpConfig({
            ...newFollowUpConfig,
            conditionsToSend: JSON.parse(newFollowUpConfig.conditionsToSend) as string,
            templateComponents: newFollowUpConfig.templateComponents
              ? (JSON.parse(newFollowUpConfig.templateComponents) as string)
              : null,
          }).catch((err) => console.error(err)); // eslint-disable-line no-console
        }}
        startIcon={<EditIcon />}
        variant="contained"
      >
        Crear
      </PrimaryBtn>
    </Box>
  );
};

export default FollowUpConfigForm;

import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import FollowUpConfigFormAttributes from 'pages/FollowUpConfig/FollowUpConfigFormAttributes';
import { useGetFollowUpCampaignsQuery } from 'services/api/followUpConfig.api';
import { FollowUpConfig } from 'services/types/followUpConfig';

interface SelectFollowUpProps {
  businessUnitId: number;
  selectFollowUp: (followUpId: FollowUpConfig) => void;
  setOpen: (open: boolean) => void;
}

const SelectFollowUp = ({ businessUnitId, selectFollowUp, setOpen }: SelectFollowUpProps) => {
  const { data: followUpCampaigns } = useGetFollowUpCampaignsQuery({ businessUnitId, isActive: true });

  return (
    <div>
      <Box display="flex" alignItems="center">
        <PrimaryBtn onClick={() => setOpen(false)}>Volver</PrimaryBtn>
        <Typography variant="h6">Selecciona un follow up para la campaña</Typography>
      </Box>
      <Grid container spacing={2}>
        {followUpCampaigns?.map((followUp) => (
          <Grid
            item
            xs={5}
            m={2}
            key={followUp.id}
            border="1px solid #e0e0e0"
            borderRadius="8px"
            onClick={() => selectFollowUp(followUp)}
            sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' } }}
          >
            <FollowUpConfigFormAttributes
              followUpConfigDraft={{
                ...followUp,
                conditionsToSend: JSON.stringify(followUp.conditionsToSend),
                templateComponents: JSON.stringify(followUp.templateComponents),
              }}
              isEditing={false}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SelectFollowUp;

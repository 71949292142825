import React, { FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

const SearchElement: FC<{ searchTerm: string; setSearchTerm: (searchTerm: string) => void }> = ({
  searchTerm,
  setSearchTerm,
}) => (
  <TextField
    autoFocus
    type="text"
    placeholder="Buscar"
    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
    value={searchTerm}
    sx={{
      '& .MuiInputBase-input': {
        fontSize: '14px',
        color: 'text.secondary',
        fontWeight: 'bold',
      },
      '& .MuiInputBase-root': {
        border: '1px solid #E5E5E5',
        borderRadius: '10px',
        padding: '0 10px',
      },
      backgroundColor: 'primary.contrastText',
      mx: 2,
    }}
    InputProps={{
      endAdornment: <SearchIcon />,
    }}
  />
);

export default SearchElement;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Send from '@mui/icons-material/Send';
import { Box, Grid, IconButton, TextField } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import Conversation from 'components/common/Conversation';
import config from 'config';
import Pusher from 'pusher-js';
import { useGetDemoClientMessagesQuery } from 'services/api/demochat';
import {
  useGetRealtimeVoiceSettingsMutation,
  useStoreMessageMutation,
  useCreateRealtimeVoiceClientMutation,
} from 'services/api/realtimeVoice';
import getReCaptchaToken from 'utils/getReCaptchaToken';

import ChatWrapper from '../Demochat/ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const { apiUrl } = config;

const RealtimeVoice = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();

  const [started, setStarted] = useState(false);
  const [previousFunctionCallId, setPreviousFunctionCallId] = useState<string | null>(null);
  const [getRealtimeVoiceSettings, { data }] = useGetRealtimeVoiceSettingsMutation();
  const [storeMessage] = useStoreMessageMutation();
  const [createRealtimeVoiceClient, { data: client }] = useCreateRealtimeVoiceClientMutation();
  const { data: messages, refetch } = useGetDemoClientMessagesQuery({
    clientPhoneIdentifier: client?.id ?? '',
  });
  const [dataChannel, setDataChannel] = useState<RTCDataChannel | null>(null);
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (!client?.id) return () => {};

    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${client.id}`);
    channel.bind('new-message', (subscriptionData: { message: string }) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', subscriptionData); // eslint-disable-line no-console
      const subscriptionDataMessage = JSON.parse(subscriptionData.message) as {
        type: string;
        item: object;
      };
      if (subscriptionDataMessage.type === 'conversation.item.create') {
        console.log('Function call output', subscriptionDataMessage, dataChannel); // eslint-disable-line no-console

        // Sends the function call output to the model
        dataChannel?.send(JSON.stringify({ ...subscriptionDataMessage, previous_item_id: previousFunctionCallId }));
        // Triggers a response from the model: https://github.com/craigsdennis/talk-to-javascript-openai-workers/issues/4
        dataChannel?.send(JSON.stringify({ type: 'response.create' }));
      }
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${client.id}`);
      pusher.disconnect();
    };
  }, [client?.id, setRefetchFlag, dataChannel]);

  const start = async () => {
    const token = await getReCaptchaToken();
    createRealtimeVoiceClient({
      companyIdentifier: companyIdentifier || '',
      recaptchaToken: token,
    }).catch(console.error); // eslint-disable-line no-console
    setStarted(true);
  };

  useEffect(() => {
    if (!companyIdentifier) return;

    getRealtimeVoiceSettings({
      companyIdentifier,
    }).catch(console.error); // eslint-disable-line no-console
  }, [companyIdentifier]);

  useEffect(() => {
    if (!companyIdentifier || !started || !client?.id) {
      return;
    }

    // Create a WebRTC Agent
    const newPeerConnection = new RTCPeerConnection();
    setPeerConnection(newPeerConnection);

    // On inbound audio add to page
    newPeerConnection.ontrack = (event) => {
      const el = document.createElement('audio');
      [el.srcObject] = event.streams;
      el.autoplay = true;
      el.controls = true;
      el.onpause = () => {
        console.log('Audio stopped'); // eslint-disable-line no-console
        setStarted(false);
        newPeerConnection.close();
      };
      const inputElement = document.getElementById('realtime-voice-input');
      if (inputElement) {
        while (inputElement.firstChild) {
          inputElement.removeChild(inputElement.firstChild);
        }
        inputElement.appendChild(el);
      }
    };

    const myDataChannel = newPeerConnection.createDataChannel('response');
    setDataChannel(myDataChannel);

    const configureData = () => {
      console.log('Configuring data channel'); // eslint-disable-line no-console

      console.log('Data', data); // eslint-disable-line no-console
      const event = {
        type: 'session.update',
        session: {
          modalities: ['text', 'audio'],
          tools: data?.tools.map((tool) => ({
            type: 'function',
            name: tool.function.name,
            description: tool.function.description,
            parameters: tool.function.parameters,
          })),
          temperature: 0.6,
          input_audio_transcription: data?.input_audio_transcription,
        },
      };
      myDataChannel.send(JSON.stringify(event));
    };

    // Capture microphone
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        // Add microphone to PeerConnection
        stream.getTracks().forEach((track) => newPeerConnection.addTransceiver(track, { direction: 'sendrecv' }));

        newPeerConnection
          .createOffer()
          .then((offer) => {
            newPeerConnection.setLocalDescription(offer).catch((error) => {
              // eslint-disable-next-line no-console
              console.error('Error setting local description', error);
            });

            // Send WebRTC Offer to Workers Realtime WebRTC API Relay
            fetch(`${apiUrl}/realtimeVoice/startChat`, {
              method: 'POST',
              body: JSON.stringify({
                sdp: offer.sdp,
                clientPhoneIdentifier: client?.id || '',
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((r) => r.json())
              .then((answer: { id: string; sessionToken: string }) => {
                console.log('Answer', answer); // eslint-disable-line no-console
                // Accept answer from Realtime WebRTC API
                newPeerConnection
                  .setRemoteDescription({
                    sdp: answer.sessionToken,
                    type: 'answer',
                  })
                  .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Error setting remote description', error);
                  });
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Error sending WebRTC Offer', error);
              });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error capturing microphone', error);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error capturing microphone', error);
      });

    myDataChannel.addEventListener('open', (ev) => {
      console.log('Opening data channel', ev); // eslint-disable-line no-console
      configureData();
    });

    myDataChannel.addEventListener('message', (event) => {
      const message = JSON.parse(event.data as string) as {
        type: string;
        event_id?: string;
        item_id?: string;
        content_index?: number;
        transcript?: string;
        response: {
          output: {
            id: string;
            type: 'message' | 'function_call';
            name?: string; // the function name
            arguments?: string; // the function arguments
            call_id?: string; // the function call id
            content?: {
              type: 'audio' | 'text';
              transcript: string;
            }[];
            role: 'assistant' | 'system' | 'user';
          }[];
          usage: object;
        };
        name: string;
        arguments: string;
        call_id: string;
      };
      console.log(JSON.stringify(message)); // eslint-disable-line no-console
      if (message.type === 'conversation.item.input_audio_transcription.completed') {
        storeMessage({
          message: {
            messageId: message.item_id,
            text: message.transcript || '',
            role: 'user',
          },
          clientPhoneIdentifier: client?.id || '',
        }).catch(console.error); // eslint-disable-line no-console
      }
      if (message.type !== 'response.done') {
        return;
      }
      const { output } = message.response;
      if (!output) {
        return;
      }

      output.forEach((outputElement) => {
        console.log('Storing message', outputElement, client?.id); // eslint-disable-line no-console
        if (outputElement.type === 'message') {
          storeMessage({
            message: {
              messageId: outputElement.id,
              text: outputElement.content ? outputElement.content[0].transcript : 'No disponible',
              role: outputElement.role,
              AIUsage: message.response.usage,
            },
            clientPhoneIdentifier: client?.id || '',
          }).catch(console.error); // eslint-disable-line no-console
        } else if (outputElement.type === 'function_call') {
          storeMessage({
            message: {
              messageId: outputElement.id,
              text: 'No disponible',
              role: 'assistant',
              functionName: outputElement.name,
              functionArguments: outputElement.arguments,
              toolCalls: [
                {
                  toolCallId: outputElement.call_id || '',
                  functionName: outputElement.name || '',
                  functionArguments: outputElement.arguments || '',
                },
              ],
              AIUsage: message.response.usage,
            },
            clientPhoneIdentifier: client?.id || '',
          }).catch(console.error); // eslint-disable-line no-console
          setPreviousFunctionCallId(outputElement.id);
          storeMessage({
            message: {
              // messageId: Will be generated by the backend
              text: 'Not finished',
              role: 'tool',
              functionName: outputElement.name,
              functionArguments: outputElement.arguments,
              toolCallId: outputElement.call_id,
            },
            clientPhoneIdentifier: client?.id || '',
          }).catch(console.error); // eslint-disable-line no-console
        }
      });
    });
  }, [companyIdentifier, client?.id]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} includeGoToNewChatButton>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Conversation conversation={messages || []} isFilteredConversation isClientView />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div id="realtime-voice-input" />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          {started ? (
            <PrimaryBtn
              onClick={() => {
                setStarted(false);
                peerConnection?.close();
              }}
              sx={{
                backgroundColor: '#fff',
                opacity: 1,
                width: '300px',
              }}
            >
              Cerrar sesión
            </PrimaryBtn>
          ) : (
            <PrimaryBtn
              onClick={() => {
                start().catch((error) => {
                  // eslint-disable-next-line no-console
                  console.error('Error starting session', error);
                });
              }}
              sx={{
                backgroundColor: '#fff',
                opacity: 1,
                width: '300px',
              }}
            >
              Empezar nueva sesión
            </PrimaryBtn>
          )}
        </Box>
        <Grid container width="100%" p={2} justifyContent="space-between">
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '80%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value=""
            onChange={() => {}}
            onKeyDown={() => {}}
            autoFocus
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={() => {}}
          >
            <Send
              sx={{
                fontSize: '2.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </div>
    </ChatWrapper>
  );
};

export default RealtimeVoice;

import { Alert } from 'services/types/alert';

import baseApi from './baseApi';

const alertsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAlerts: build.query({
      query: () => ({
        url: `/alerts`,
        method: 'POST',
        body: { isArchived: false },
      }),
      transformResponse: (response: Alert[]) => response,
    }),
    readAlert: build.mutation({
      query: (params: { alertId: number; readAlert: boolean }) => ({
        url: `/alerts/readAlert`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: Alert) => response,
    }),
    archiveAlert: build.mutation({
      query: (params: { alertId: number; archiveAlert: boolean }) => ({
        url: `/alerts/archiveAlert`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: Alert) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAlertsQuery, useReadAlertMutation, useArchiveAlertMutation } = alertsApi;

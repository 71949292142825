import { CarModel, CarModelDraft } from 'services/types/carModel';

import baseApi from './baseApi';

const carModelsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarModels: builder.query({
      query: () => ({
        url: '/admin/carModel',
        method: 'GET',
      }),
      transformResponse: (response: CarModel[]) => response,
    }),
    createCarModel: builder.mutation({
      query: (body: CarModelDraft) => ({
        url: '/admin/carModel',
        method: 'POST',
        body,
      }),
    }),
    updateCarModel: builder.mutation({
      query: ({ id, body }: { id: number; body: CarModelDraft }) => ({
        url: `/admin/carModel/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteCarModel: builder.mutation({
      query: (id: number) => ({
        url: `/admin/carModel/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetCarModelsQuery, useCreateCarModelMutation, useUpdateCarModelMutation, useDeleteCarModelMutation } =
  carModelsApi;
